import { Button } from '@chakra-ui/button'
import { Image } from '@chakra-ui/image'
import { Box, Divider, Flex, Heading, Spacer, Text, VStack } from '@chakra-ui/layout'
import React from 'react'
import Navbar from './Components/Navbar'

const Notification = () => {
    return (
        <div>
          <Navbar/>
          <VStack alignItems="left" m="56px">
              <Heading size="lg" mb="16px">Notifikasi</Heading>
              <Flex direction="row">
                <Box>
                    <Button bgColor="#23AFBF" color="white" borderRadius={40} mr="12px">Promo</Button>
                    <Button bgColor="#ffffff" color="#545454" border="2px" borderRadius={40}>Update Pesanan</Button>
                </Box>
              </Flex>
              
              <Flex direction="row">
                <Image boxSize="150px" src={process.env.PUBLIC_URL+"/images/banner.png"} mr="32px" mt="24px" mb="16px"/>
                <Box mt="24px">
                    <Text color="#22ADBC" fontSize="xl" fontWeight="semibold">Promo Belanja Mumer 12 12</Text>
                    <Text color="#545454" fontSize="lg" fontWeight="semibold" mt="8px">Temukan kejutan menarik dan promo lainnya</Text>
                </Box>
                <Spacer/>
                <Text mt="24px" color="#545454" fontWeight="medium">19:31 WIB</Text>
              </Flex>

              <Divider/>

              <Flex direction="row">
                <Image boxSize="150px" src={process.env.PUBLIC_URL+"/images/banner.png"} mr="32px" mt="24px" mb="16px"/>
                <Box mt="24px">
                    <Text color="#22ADBC" fontSize="xl" fontWeight="semibold">Promo Belanja Mumer 12 12</Text>
                    <Text color="#545454" fontSize="lg" fontWeight="semibold" mt="8px">Temukan kejutan menarik dan promo lainnya</Text>
                </Box>
                <Spacer/>
                <Text mt="24px" color="#545454" fontWeight="medium">19:31 WIB</Text>
              </Flex>
              
              <Divider/>

              <Flex direction="row">
                <Image boxSize="150px" src={process.env.PUBLIC_URL+"/images/banner.png"} mr="32px" mt="24px" mb="16px"/>
                <Box mt="24px">
                    <Text color="#22ADBC" fontSize="xl" fontWeight="semibold">Promo Belanja Mumer 12 12</Text>
                    <Text color="#545454" fontSize="lg" fontWeight="semibold" mt="8px">Temukan kejutan menarik dan promo lainnya</Text>
                </Box>
                <Spacer/>
                <Text mt="24px" color="#545454" fontWeight="medium">19:31 WIB</Text>
              </Flex>

          </VStack>  
        </div>
    )
}

export default Notification
