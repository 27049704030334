
import { Box, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Lottie from "react-lottie";
import animationLoading from "../lottie/890-loading-animation.json";

const Loading = (props) => {

  let lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: animationLoading,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  }

  return(
    <Box w="100%" mt={{base:"40%", lg:"15%"}} align="center" {...props}>
    <Lottie
          options={lottieOptions}
          height={100}
          width={100}/>
    <Text>Sedang mempersiapkan data</Text>
  </Box>
  )
}

export default Loading