import axios from 'axios';
import React from 'react';

const BASE_URL = "https://backend.kioos.id";
const API_KEY = "mitrawarungkuwkwk";

class ProductServices {

    loadProducts(search,limit, offset){
        // axios.defaults.headers.post['Content-Type'] ='application/json';
        axios.defaults.headers.post['Access-Control-Allow-Origin'] = "*";
        var url = BASE_URL + "/rest/products/search/api_key/"+ API_KEY+"/limit/"+limit+"/offset/"+offset;
        return axios.post(url,search);
    }

    loadNearestProducts(search,limit, offset){
        // axios.defaults.headers.post['Content-Type'] ='application/json';
        axios.defaults.headers.post['Access-Control-Allow-Origin'] = "*";
        var url = BASE_URL + "/rest/products/nearest_products/api_key/"+ API_KEY+"/limit/"+limit+"/offset/"+offset;
        return axios.post(url,search);
    }

    getProductDetail(id){
        axios.defaults.headers.post['Access-Control-Allow-Origin'] = "*";
        var url = BASE_URL + "/rest/products/get/api_key/"+ API_KEY+"/id/"+id;
        return axios.get(url);
    }

}

export default new ProductServices()