import React, { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import {
  Box,
  Flex,
  Wrap,
  WrapItem,
  Text,
  Image,
  useDisclosure,
  Heading,
  Avatar,
  HStack,
  VStack,
  Stack,
  useBoolean,
} from "@chakra-ui/react";
import Navbar from "./Components/Navbar";
import Category from "./Components/Category";
import KatalogCard from "./Components/KatalogCard";
import BannerServices from "./Services/BannerServices";
import CategoryServices from "./Services/CategoryServices";
import ProductServices from "./Services/ProductServices";
import StoreServices from "./Services/StoreServices";
import { Link, useHistory } from "react-router-dom";
import useLocalStorage from "./Tools/LocalStorage";
import Loading from "./Components/Loading";
import Footer from "./Components/Footer";
import ReactGA from "react-ga";

const Home = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  // const handleToggle = () => (isOpen ? onClose() : onOpen());
  let numberOfSlide = 3;
  const [datas, setDatas] = useState({
    banners: [],
    categories: [],
    products: [],
    stores: [],
  });
  const [userId, setUserId] = useLocalStorage("userId", "");
  const [isLogin, setIsLogin] = useLocalStorage("isLogin", false);
  const [isLoading, setIsLoading] = useBoolean();
  const [location, setLocation] = useLocalStorage({
    lat: 0,
    lng: 0
  });
  const history = useHistory();

  const onClickCategory = (id, name) => {
    history.push({
      pathname: "/cari",
      state: {
        key: "Kategori " + name,
        categoryId: id,
      },
    });
  };

  function loadDataWithLocation(lat, lng, isActive) {
    let search = {
      searchterm: "",
      order_by: "added_date",
      order_type: "desc",
      loc_lng: lng,
      loc_lat: lat,
    };

    setIsLoading.on();
    Promise.all([
      BannerServices.loadAllBanner(10, 0).catch((e) => {
        ReactGA.exception({
          description: 'Error Load Banner :'+e.message,
          fatal: true
        });

        return { data: [] };
      }),
      ProductServices.loadNearestProducts(search, 12, 0).catch((e) => {
        ReactGA.exception({
          description: 'Error Load Product :'+e.message,
          fatal: true
        });
        return { data: [] };
      }),
      CategoryServices.loadAllCategories(12, 0).catch((e) => {
        ReactGA.exception({
          description: 'Error Load Categories :'+e.message,
          fatal: true
        });
        return { data: [] };
      }),
      StoreServices.loadNearestStore(lat, lng, 0, 0).catch((e) => {
        ReactGA.exception({
          description: 'Error Load Store :'+e.message,
          fatal: true
        });
        return { data: [] };
      }),
    ])
      .then(function ([banner, product, category, store]) {
        if (isActive) {
          if (store != undefined) {
            setDatas({
              banners: banner.data,
              categories: category.data,
              products: product.data,
              stores: store.data,
            });
          } else {
            setDatas({
              banners: banner.data,
              categories: category.data,
              products: product.data,
            });
          }
          setIsLoading.off();
        }
      })
      .catch((error) => {
        // console.log(error);
        ReactGA.exception({
          description: 'Error Load All Data :'+error.message,
          fatal: true
        });
        setIsLoading.off();
      });
  }

  function loadDataWithoutLocation(isActive) {
    let search = {
      searchterm: "",
      order_by: "added_date",
      order_type: "desc",
    };

    setIsLoading.on();
    Promise.all([
      BannerServices.loadAllBanner(10, 0).catch((e) => {
        ReactGA.exception({
          description: 'Error Load Banner :'+e.message,
          fatal: true
        });

        return { data: [] };
      }),
      ProductServices.loadProducts(search, 12, 0).catch((e) => {
        ReactGA.exception({
          description: 'Error Load Products :'+e.message,
          fatal: true
        });
        return { data: [] };
      }),
      CategoryServices.loadAllCategories(12, 0).catch((e) => {
        ReactGA.exception({
          description: 'Error Load Categories :'+e.message,
          fatal: true
        });

        return { data: [] };
      }),
    ])
      .then(function ([banner, product, category]) {
        if (isActive) {
          setDatas({
            banners: banner.data,
            categories: category.data,
            products: product.data,
          });
        }
        setIsLoading.off();
      })
      .catch((error) => {
        // console.log(error);
        ReactGA.exception({
          description: 'Error Load All Data :'+error.message,
          fatal: true
        });

        setIsLoading.off();
      });
  }

  useEffect(() => {
    if (isLogin) {
      // console.log("user login with id" + userId);
      console.log("user already login");
    }
  }, []);

  useEffect(() => {
    let isActive = true;

    setIsLoading.on();
    if (location != undefined && location?.lat !== 0 && location?.lng !== 0){
      loadDataWithLocation(
        location.lat,
        location.lng,
        isActive
      );
    }else if ("geolocation" in navigator) {
      console.log("Location Available");
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // console.log("Location Latitude is :", position.coords.latitude);
          // console.log("Location Longitude is :", position.coords.longitude);
          loadDataWithLocation(
            position.coords.latitude,
            position.coords.longitude,
            isActive
          );

          setLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude
          })
        },
        (error) => {
          ReactGA.exception({
            description: 'Error Get Location :'+error.message,
            fatal: true
          });
  
          // console.log("Error Code = " + error.code + " - " + error.message);
          loadDataWithoutLocation(isActive);
        }
      );
    } else {
      console.log("Location Not Available");
      loadDataWithoutLocation(isActive);
    }

    return () => (isActive = false);
  }, []);

  return (
    <Box w="100%" h="100%">
      <Navbar />
      <Loading d={isLoading ? "box" : "none"} />

      <Box w="100%" d={isLoading ? "none" : "box"}>
        <Box
          d={{ base: "flex", lg: "none" }}
          mt={{ base: 4, lg: 12 }}
          px={{ base: 4, lg: 20 }}
        >
          <Carousel axis="horizontal" autoPlay={true} infiniteLoop={true}>
            {datas.banners &&
              datas.banners.map((item) => {
                return (
                  <Image
                    src={
                      "https://backend.kioos.id/uploads/" +
                      item["default_photo"]["img_path"]
                    }
                    alt={item["default_photo"]["img_path"]}
                  />
                );
              })}
          </Carousel>
        </Box>

        <Box
          d={{ base: "none", lg: "flex" }}
          mt={{ base: 4, lg: 12 }}
          px={{ base: 8, lg: 20 }}
        >
          <Carousel
            axis="horizontal"
            autoPlay={true}
            infiniteLoop={true}
            centerMode={true}
            centerSlidePercentage={100 / numberOfSlide}
          >
            {datas.banners &&
              datas.banners.map((item) => {
                return (
                  <Image
                    src={
                      "https://backend.kioos.id/uploads/" +
                      item["default_photo"]["img_path"]
                    }
                    alt={item["default_photo"]["img_path"]}
                    px={1}
                  />
                );
              })}
          </Carousel>
        </Box>

        <Flex
          mt={{ base: 0, lg: 16 }}
          mb={4}
          justify="space-between"
          alignItems="center"
        >
          <Box pl={{ base: 4, lg: 20 }} py={2}>
            <Heading as="h2" fontSize={["md", "2xl"]}>
              Kategori
            </Heading>
          </Box>
          <Box pr={{ base: 4, lg: 20 }}>
            {/* <Heading as="h4" fontSize={["xs", "md"]}>
              Lihat Semua
            </Heading> */}
          </Box>
        </Flex>

        <Box
          overflowX="auto"
          spacing="30px"
          mb="20px"
          mx={{ base: 4, lg: 20 }}
          mt="4px"
          w="90%"
          sx={{"::-webkit-scrollbar":{
            height: "10px",
            width: "10px"
          }}}
        >
          <HStack px={{ base: 4, lg: 8 }} spacing={{ base: 2, lg: 8 }}>
            {datas.categories.map((item) => {
              // console.log(item)
              return (
                <Category
                  onClick={(e) => onClickCategory(item.id, item.name)}
                  item={item}
                />
              );
            })}
          </HStack>
        </Box>

        {datas.stores?.length > 0 ? (
          <HomeStore stores={datas.stores} />
        ) : (
          <div></div>
        )}

        <Box
          p="2"
          mt={[4, 8]}
          pl={{ base: 4, lg: 20 }}
          alignItems="center"
          w="100%"
        >
          <Heading as="h2" fontSize={["md", "2xl"]}>
            Produk Terlaris
          </Heading>
        </Box>

        <Flex py={[4, 8]} pl={{ base: 4, lg: 20 }} direction="column">
          <Wrap justify="space-evenly" spacing={[2, 4]} w="95%">
            {datas.products.map((item) => {
              // console.log(item);
              return (
                <WrapItem>
                  <KatalogCard
                    imageUrl={
                      "https://backend.kioos.id/uploads/" +
                      item["default_photo"]["img_path"]
                    }
                    imageAlt={item["default_photo"]["img_path"]}
                    title={item.name}
                    category={item.category.name}
                    formattedPrice={
                      item.currency_short_form + " " + item.unit_price
                    }
                    originalPrice={
                      item.currency_short_form + " " + item.original_price
                    }
                    reviewCount={item.rating_details.total_rating_count}
                    rating={item.overall_rating}
                    favorite={item.favourite_count}
                    sellingCount={item.touch_count}
                    productId={item.id}
                    shopId={item.shop_id}
                  />
                </WrapItem>
              );
            })}
          </Wrap>
        </Flex>
        <Footer />
      </Box>
    </Box>
  );
};

const HomeStore = (props) => {
  return (
    <div>
      <Flex
        mt={{ base: 8, lg: 16 }}
        mb={4}
        justify="space-between"
        alignItems="center"
      >
        <Box p="2" pl={{ base: 4, lg: 20 }}>
          <Heading as="h2" fontSize={["md", "2xl"]}>
            Toko Terdekat
          </Heading>
        </Box>
        <Box pr={{ base: 4, lg: 20 }}>
          {/* <Heading as="h4" fontSize={["xs", "md"]}>
            Lihat Semua
          </Heading> */}
        </Box>
      </Flex>

      <Box overflowX="auto" pl={{ base: 4, lg: 20 }} mt="4px">
        <HStack>
          {props.stores.map((item) => {
            return (
              <Link
                to={{
                  pathname: "./kios/" + item.name,
                  state: {
                    photo:
                      "https://backend.kioos.id/uploads/" +
                      item["default_photo"]["img_path"],
                    name: item.name,
                    id: item.id,
                    description: item.description,
                    lat: item.lat,
                    lng: item.lng,
                    address: item.address1,
                    distance: item.distance_in_km,
                    email: item.email,
                  },
                }}
              >
                <Box
                  align="center"
                  bgColor="#FBFBFB"
                  padding={[4, 8]}
                  borderRadius={16}
                  pr={[1, 4]}
                >
                  <Avatar
                    src={
                      "https://backend.kioos.id/uploads/" +
                      item["default_photo"]["img_path"]
                    }
                    width={{ base: 8, lg: 24 }}
                    height={{ base: 8, lg: 24 }}
                  />
                  <Text
                    fontWeight="semibold"
                    color="#545454"
                    WrapItem={true}
                    fontSize={{ base: 8, md: 10, lg: 14 }}
                    mt={2}
                  >
                    {item.name}
                  </Text>
                </Box>
              </Link>
            );
          })}
        </HStack>
      </Box>
    </div>
  );
};

export default Home;
