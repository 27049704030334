import { Icon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Container,
  Divider,
  Flex,
  Heading,
  HStack,
  IconButton,
  Image,
  Input,
  Link,
  SimpleGrid,
  Stack,
  Text,
  useColorModeValue,
  VisuallyHidden,
  VStack,
} from "@chakra-ui/react";
import {
  FaFacebookF,
  FaWhatsapp,
  FaInstagram,
  FaLinkedin,
  FaYoutube,
  FaTwitter,
  FaGoogle,
  FaVoicemail,
} from "react-icons/fa";
import { Link as RouterLink } from "react-router-dom";

const Logo = (props) => {
  const logo = process.env.PUBLIC_URL+"/images/logo.png";
  return (
    <Box {...props}>
      <Link to={"/"}>
        <Image
          src={logo}
          as="img"
          w="100px"
          mr={8}
        />
      </Link>
    </Box>
  );
};

const SocialButton = ({ children, label, href }) => {
  return (
    <Button
      bg={useColorModeValue("blackAlpha.100", "whiteAlpha.100")}
      rounded={"full"}
      w={[10,12]}
      h={[10,12]}
      cursor={"pointer"}
      as={"a"}
      href={href}
      display={"inline-flex"}
      alignItems={"center"}
      justifyContent={"center"}
      transition={"background 0.3s ease"}
      _hover={{
        bg: useColorModeValue("blackAlpha.200", "whiteAlpha.200"),
      }}
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </Button>
  );
};

const ListHeader = ({ children }) => {
  return (
    <Text fontWeight={"500"} fontSize={"lg"} mb={2}>
      {children}
    </Text>
  );
};

const Footer = (props) => {
  return (
    <Box
      bg={useColorModeValue("gray.50", "gray.900")}
      color={useColorModeValue("gray.700", "gray.200")}
    >
      <Container as={Stack} maxW={"6xl"} py={10}>
        <SimpleGrid
          templateColumns={{ sm: "1fr 1fr", md: "2fr 1fr 1fr 2fr" }}
          spacing={8}
        >
          <Stack spacing={4}>
            <Box>
              <Logo color={useColorModeValue("gray.700", "white")} />
            </Box>
            <Text fontSize={"sm"}>
              © 2020 KIOOS. All rights reserved
            </Text>
            <Stack direction={"row"} spacing={[2,4]}>
              <SocialButton label={"Instagram"} href={"https://www.instagram.com/kioos.id/?hl=id"}>
                <FaInstagram />
              </SocialButton>
              <SocialButton label={"Facebook"} href={"https://www.facebook.com/kioos.indonesia/"}>
                <FaFacebookF />
              </SocialButton>
              <SocialButton label={"Google"} href={"https://g.page/r/CXeCAvoNoQwGEBA"}>
                <FaGoogle />
              </SocialButton>
              <SocialButton label={"YouTube"} href={"https://www.youtube.com/channel/UC3RPARlMZCuftsZ8kRi-8Hg"}>
                <FaYoutube />
              </SocialButton>
            </Stack>
          </Stack>
          <Stack align={"flex-start"}>
            <ListHeader>Company</ListHeader>
            <Link as={RouterLink} to={"/about"}>About us</Link>
            <Link href={"https://wa.me/6282130537608"} target="_blank">Contact us</Link>
            {/* <Link href={"#"}>Testimonials</Link> */}
          </Stack>
          <Stack align={"flex-start"}>
            <ListHeader>Support</ListHeader>
            <Link href={"mailto:kioos.team@gmail.com"} target="_blank">Help Center</Link>
            {/* <Link href={"#"}>Terms of Service</Link>
            <Link href={"#"}>Legal</Link>
            <Link href={"#"}>Privacy Policy</Link> */}
          </Stack>
          <Stack align={"flex-start"}>
            <ListHeader>Install</ListHeader>
            <Link href="https://play.google.com/store/apps/details?id=id.kioos.kio&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
              <Image
                w={[48]}
                alt="Temukan di Google Play"
                src="https://play.google.com/intl/en_us/badges/static/images/badges/id_badge_web_generic.png"
              />
            </Link>
          </Stack>
        </SimpleGrid>
      </Container>
    </Box>
  );
};

export default Footer;
