import axios from 'axios';
import React from 'react';

const BASE_URL = "https://backend.kioos.id";
const API_KEY = "mitrawarungkuwkwk";

class StoreServices {

    loadNearestStore(lat,lng,limit, offset){
        let location = {
            loc_lat: lat,
            loc_lng: lng
        }
        // axios.defaults.headers.post['Content-Type'] ='application/json';
        axios.defaults.headers.post['Access-Control-Allow-Origin'] = "*";
        var url = BASE_URL + "/rest/shops/get_nearest_shops/api_key/"+ API_KEY+"/limit/"+limit+"/offset/"+offset;
        return axios.post(url,location);
    }

    getStoreDetail(id){
        axios.defaults.headers.post['Access-Control-Allow-Origin'] = "*";
        var url = BASE_URL + "/rest/shops/get/api_key/"+ API_KEY+"/id/"+id;
        return axios.get(url);
    }

}

export default new StoreServices()