import React, { Component, useState } from 'react'
import {
    Grid, Box, Image, VStack, FormControl, FormLabel, Input,
    FormHelperText, Text, Button
} from '@chakra-ui/react'
import AuthServices from './Services/AuthServices'
import useLocalStorage from './Tools/LocalStorage'
import { Link, useHistory, withRouter } from 'react-router-dom'
import ReactGA from 'react-ga'

const Login = (props) => {
    const [userData, setUserData] = useLocalStorage("user",{
        id: "",
        name: "",
        photo: ""
    });
    const [isLogin, setIsLogin] = useLocalStorage("isLogin",false);
    const [email, setEmail] = useState("");
    const [pass,setPass] = useState("");
    const history = useHistory();

    const doLogin = (e) => {
        let account = {
            user_email: email,
            user_password: pass,
        }
        // console.log('account => ' + JSON.stringify(account));
                        
        ReactGA.event({
            category: 'Login',
            action: 'Login with Email ',
            value: email
          });


        AuthServices.doLogin(account).then(res => {
            // console.log(res);
            alert('Berhasil Login');
            setUserData({
                id: res.data.user_id,
                name: res.data.user_name,
                photo: "https://backend.kioos.id/uploads/" + res.data.user_profile_photo
            });
            setIsLogin(true);

            history.push({
                pathname:"/",
                state: {
                    userId: res.data.user_id,
                    photos: "https://backend.kioos.id/uploads/" + res.data.user_profile_photo,
                    name: res.data.user_name
                }
            });
        }).catch(error => {
            if( error.response ){
                // console.log(error.response.data);
                alert(error.response.data.message);
                
                ReactGA.exception({
                    description: 'Error Login : '+error.message,
                    fatal: true
                  });
        
            }
        })
    }

        return (
            <Grid templateColumns="repeat(2, 1fr)" gap={0}>
                <Box width="100%" bg="#22AFBF">
                    <Image src={process.env.PUBLIC_URL+"/images/kio.png"} w="60%" alt="Segun Adebayo" pos="relative" left="20%" top="20%" />
                </Box>
                <Box width="100%" height={658} bg="white">
                    <VStack spacing="15px">
                        <Image src={process.env.PUBLIC_URL+"/images/logo.png"} w="20%" alt="Segun Adebayo" mt="16" />
                        <Box boxShadow="md" p="6" rounded="md" bg="white" w="60%">
                            <FormControl id="email" mb="4">
                                <FormLabel>Email</FormLabel>
                                <Input type="email" value={email} onChange={(e)=>setEmail(e.target.value)} />
                                <FormHelperText>contoh: email@kioos.com</FormHelperText>
                            </FormControl>

                            <FormControl id="pass">
                                <FormLabel>Password</FormLabel>
                                <Input type="password" value={pass} onChange={(e)=>setPass(e.target.value)}/>
                            </FormControl>

                            <Text textAlign="right" fontSize="small" mt="4" mb="4">Lupa Kata Sandi ?</Text>

                            <Button colorScheme="teal" size="md" w="100%" onClick={doLogin}>
                                Masuk
                            </Button>
                        </Box>
                        <Text textAlign="center" fontSize="md" mt="16">Belum Punya Akun ?</Text>
                        <Link to="/register">
                            <Text textAlign="center" fontSize="md" color="#22AFBF" fontWeight="bold">Daftar</Text>                        
                        </Link>
                    </VStack>

                </Box>
            </Grid>
        )
    }

export default Login
