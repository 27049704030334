import React from "react";
import {
  SimpleGrid,
  IconButton,
  Box,
  Text,
  Wrap,
  Heading,
  Flex,
  Image,
  HStack,
  Link
} from "@chakra-ui/react";
import {
  HamburgerIcon,
  SearchIcon,
  BellIcon,
  ChevronRightIcon,
} from "@chakra-ui/icons";
import { useHistory } from "react-router-dom";

const Category = ({onClick, item, props}) => {
  return (
    <Flex
      as={Link}
      onClick={onClick}
      direction="column"
      alignSelf="flex-start"
      {...props}
    >
      <Box
        align="center"
        bgColor="#FBFBFB"
        width={{ base: 50, lg: 100 }}
        height={{ base: 50, lg: 100 }}
        borderRadius="100%"
        border="1px solid #23AFBF"
      >
        <Image
          w={{ base: 25, lg: 50 }}
          h={{ base: 25, lg: 50 }}
          mt="25%"
          src={
            "https://backend.kioos.id/uploads/" + item.default_photo?.img_path
          }
          fallbackSrc={"https://upload.wikimedia.org/wikipedia/commons/1/14/Product_sample_icon_picture.png"}
        />
      </Box>
      <Text 
      textAlign="center" w="100%" fontSize={{ base: 11, lg: 16 }}>
        {item.name}
      </Text>
    </Flex>
  );
};

export default Category;
