import React from 'react'
import { Image, Box, Text, Wrap, Heading, Flex, Icon } from '@chakra-ui/react'
import { StarIcon, SearchIcon, BellIcon, ChevronRightIcon } from "@chakra-ui/icons"
import { Link } from 'react-router-dom'
import { FaHeart, FaStar, FaShoppingBasket } from 'react-icons/fa'

const KatalogCard = (props) => {
    const PlaceHolder = process.env.PUBLIC_URL+"/images/product-placeholder.jpg";
    const property = {
        imageUrl: props.imageUrl,
        imageAlt: props.imageAlt,
        title: props.title,
        category: props.category,
        originalPrice: props.originalPrice,
        formattedPrice: props.formattedPrice,
        sellingCount: props.sellingCount,
        favorite: props.favorite,
        rating: props.rating,
        productId: props.productId,
        shopId: props.shopId
    }
                
    return (
        <Box 
        boxShadow="base"
        borderWidth="1px" 
        borderRadius="lg" 
        w={{base:140,lg:288}}
        // w={{base:"40%",lg:"30%"}}
        >
            <Link to={{
                pathname: "/barang/" + property.title,
                state: {
                    productId: property.productId,
                    shopId: property.shopId
                }
            }}>
                <Image src={property.imageUrl} 
                fallbackSrc={PlaceHolder}
                    h={{base:150,lg:300}} 
                    w="100%"/>
                <Box p="4">
                    <Text
                        noOfLines={2}
                        height={{base: 12, lg: 8}}
                        fontWeight="semibold"
                        fontSize={["md","xl"]}
                        color="#23AFBF"
                    >
                        {property.title}
                    </Text>
                    <Text
                        mt="1"
                        fontSize={["xs","md"]}
                        lineHeight="tight"
                        color="#494949"
                        isTruncated
                    >
                        {property.category}
                    </Text>

                    <Flex mt="2" align="center" mb={2}>
                        <Icon as={FaStar} w={{base:4, lg:4}} h={{base:4, lg:4}} color="orange" />
                        <Text fontSize={{base:11, md:14}} ml="2">{property.rating}</Text>
                        <Icon as={FaHeart} d={{base:"flex",lg:"none"}} ml={2} w={{base:4, lg:4}} h={{base:4, lg:4}} color="red" />
                        <Text d={{base:"none",lg:"flex"}} ml="2" color="gray.600" fontSize={["xs","sm"]}>
                            Disukai
                        </Text>
                        <Text ml="2" color="gray.600" fontSize={["xs","sm"]}>
                            {property.sellingCount}
                        </Text>
                        <Icon as={FaShoppingBasket} d={{base:"flex",lg:"none"}} ml={2} w={{base:4, lg:4}} h={{base:4, lg:4}} color="grey" />                        
                        <Text d={{base:"none",lg:"flex"}} ml="2" color="gray.600" fontSize={["xs","sm"]}>
                            Terjual
                        </Text>
                        <Text ml="2" color="gray.600" fontSize={["xs","sm"]}>
                            {property.sellingCount}
                        </Text>
                    </Flex>

                    {
                        property.originalPrice != property.formattedPrice ?
                            <Text 
                                fontSize={["xs","sm"]} 
                                mt={1} 
                                fontWeight="semibold" 
                                color="#888888" 
                                style={{ textDecorationLine: 'line-through', textDecorationStyle: 'solid' }}>
                                {property.originalPrice}
                            </Text> : <div></div>
                    }


                    <Text fontSize={["xl","2xl"]} fontWeight="semibold" color="#F9A53A">
                        {property.formattedPrice}
                    </Text>


                </Box>
            </Link>
        </Box>

    )
}

export default KatalogCard
