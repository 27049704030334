import { Box, Container, Flex, Image } from "@chakra-ui/react"
import { Link } from "react-router-dom";
import AboutFeature from "./Components/AboutFeature";
import AboutHeader from "./Components/AboutHeader";
import AboutNavbar from "./Components/AboutNavbar";
import AboutStory from "./Components/AboutStory";
import AboutTestimoni from "./Components/AboutTestimoni";
import Footer from "./Components/Footer";
import {useEffect, useRef} from "react"


const About = () => {
    const itemsRef = useRef([])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    
    return(
        <Flex w="100%" direction="column">
            <AboutNavbar />
            <AboutHeader id="Home"/>
            <AboutFeature id="Feature"/>
            <AboutStory id="Mitra"/>
            <AboutTestimoni id="Testimoni"/>
            <Footer />
        </Flex>
    )
}
export default About