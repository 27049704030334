import React, { useState } from 'react'
import { Input, FormLabel, FormControl, Box, Flex, Image, Heading, Text, Button, Center } from "@chakra-ui/react"
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import AuthServices from './Services/AuthServices'
import useLocalStorage from './Tools/LocalStorage'
import ReactGA from 'react-ga'

const Verification = (props) => {
    const [kode, setKode] = useState("");
    const [userData, setUserData] = useLocalStorage("user",{
        id: "",
        name: "",
        photo: ""
    });
    const [isLogin, setIsLogin] = useLocalStorage("isLogin",false);
    const history = useHistory();

    const doVerify = (e) => {
        // console.log(kode);
        e.preventDefault();
        AuthServices.doVerify(props.location.state.userId,kode).then(res => {
            // console.log(res.data);
            alert('Berhasil Register');
            setUserData({
                id: res.data.user_id,
                name: res.data.user_name,
                photo: res.data.user_profile_photo
            });
            setIsLogin(true);
            history.push({
                pathname: '/',
                state: {
                    userId: res.data.user_id,
                    photos: "https://backend.kioos.id/uploads/" + res.data.user_profile_photo,
                    name: res.data.user_name
                }
            });

        }).catch(error => {
            if( error.response ){
                // console.log(error.response.data);
                alert(error.response.data.message);

                ReactGA.exception({
                    description: 'Error Login : '+error.message,
                    fatal: true
                  });

            }
        })
    }

    return (
        <div>
        <Center mb={4}>
        <Image src={process.env.PUBLIC_URL+"/images/logo.png"} w="15%" alt="Segun Adebayo" mt="16"/>
        </Center>
        <Center>
            <Box border="1px solid #f2f2f2" ml={16} mr={24} w="40%">
            <Flex ml={12} mt={6} mb={6}>
                <Box mt={2} mr={16}>
                    <Heading color="#F39D30" fontWeight="semibold">Verifikasi</Heading>
                    <Text mb={2}>Cek email untuk mendapatkan kode verifikasi</Text>
                    <FormControl id="verifikasi" mb="4">
                        <FormLabel>Masukan Kode Verifikasi</FormLabel>
                        <Input type="text" value={kode} onChange={(e)=>setKode(e.target.value)}/>
                    </FormControl>
                    <Box d="flex">
                        <Button bgColor="#23AFBF" color="#ffffff" onClick={doVerify}>Verifikasi</Button>
                        <Text ml={2} mt={2} color="#F39D30">Resend</Text>
                    </Box>
                </Box>    
            </Flex>
            </Box>
        </Center>
        </div>
    )
}

export default Verification
