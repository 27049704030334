import { Button } from '@chakra-ui/button'
import { Box, Heading, VStack, Flex, Spacer, Text } from '@chakra-ui/layout'
import React from 'react'
// import { Navbar } from './Components/Navbar'
import Navbar from './Components/Navbar'

const DetailBanner = () => {
    const bannerdetail = process.env.PUBLIC_URL+"/images/bannerdetail.png"

    return (
        <div>
            <Navbar/>
            <VStack mt="24px" mb="24px">
                <img src={bannerdetail} alt="banner-detail"/>
                <Box maxW="5xl" w="100%"> 
                <Heading as="h1" size="lg" mt="16px" mb="16px" color="#545454">Promo Grand Launching KIOOS</Heading>
                </Box>
                <Box maxW="5xl" w="100%" p="24px" borderWidth="1px" mb="24px">
                    <Flex direction="row">
                        <Heading as="h1" size="lg" color="#F9A53A">Voucher Gratis Ongkir</Heading>
                        <Spacer/>
                        <Button bgColor="#23AFBF" color="white" size="lg" borderRadius={32}>Salin</Button>
                    </Flex>
                    <Flex direction="row">
                        <Box>
                            <Heading as="h3" size="md" mr="24px" mt="16px" color="#545454">Kode Promo</Heading>
                        </Box>
                        <Box bg="#F9A53A" p={4} mt="2px" fontSize="xl" color="white" textAlign="center" borderRadius={24}>
                            KIOOSPROMOSI
                        </Box>
                    </Flex>
                    <Text color="#E95D5D" size="md" mt="12px">Berlaku sampai dengan 15 Mei 2021 </Text>
                </Box>
                <Box maxW="5xl" w="100%"> 
                    <Heading as="h1" size="md" mt="16px" mb="16px" color="#545454">Syarat & Ketentuan</Heading>
                    <Text color="#545454">
                    1. Voucher berlaku untuk setiap transaksi sampai tanggal 15 Mei 2021<br/>
                    2. Voucher tidak dapat digabungkan<br/>
                    3. Cara menggunakan voucher: <br/>
                    Masukkan voucher ke kolom ; Kode Promo pada saat transaksi<br/>
                    Secara otomatis voucher akan mengurangi nilai transaksi<br/>
                    Voucher hanya berlaku untuk pembelian di website ; www.kioos.co.id
                    </Text>
                </Box>
            </VStack>   
        </div>
    )
}

export default DetailBanner
