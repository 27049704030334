import React from "react";
import Navbar from "./Components/Navbar";
import { StarIcon, AddIcon, MinusIcon } from "@chakra-ui/icons";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Image,
  Checkbox,
  IconButton,
  Box,
  Flex,
  Spacer,
  Heading,
  Text,
  Table,
  Tbody,
  Tr,
  Td,
  Button,
  HStack,
  Divider,
  useToast,
} from "@chakra-ui/react";
import useLocalStorage from "./Tools/LocalStorage";
import { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";
import Footer from "./Components/Footer";
import ReactGA from "react-ga";

const Cart = (props) => {
  const [selectedIds, setSelectedId] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [carts, setCart] = useLocalStorage("carts", []);
  const cancelRef = useRef();
  const [price, setPrice] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [qty, setQty] = useState(0);
  const [total, setTotal] = useState(0);
  const toast = useToast();

  const onClose = () => {
    selectedIds.forEach((x) => {
      deletedItem(x);
    });

    toast({
      position: "top-right",
      title: "Berhasil Menghapus",
      description: "Barang di keranjang berhasil dihapus",
      status: "success",
      duration: 5000,
      isClosable: true,
    });

    ReactGA.event({
      category: 'Cart',
      action: 'Deleted Cart',
      label: 'Product Ids : '+selectedIds.map((x)=>x+",")
    });

    setSelectedId([]);

    setIsOpen(false);
  };

  const onDismiss = () => {
    setIsOpen(false);
  };

  const increaseQty = (productId) => {
    const exist = carts.find((x) => x.product.id == productId);
    if (exist) {
      setCart(
        [...carts].map((object) => {
          if (object.product.id == productId) {
            return {
              ...object,
              qty: object.qty + 1,
            };
          } else return object;
        })
      );

    }
  };

  const decreaseQty = (productId) => {
    const exist = carts.find((x) => x.product.id == productId);
    if (exist) {
      // carts.map((x) => x.product.id == productId && x.qty > 0 ? x.qty = x.qty - 1 : x)
      // setCart(carts)
      setCart(
        [...carts].map((object) => {
          if (object.product.id == productId && object.qty > 1) {
            return {
              ...object,
              qty: object.qty - 1,
            };
          } else return object;
        })
      );
    }
  };

  const deletedItem = (productId) => {
    const exist = carts.find((x) => x.product.id == productId);
    if (exist) {
      // const newCarts = carts.filter((item)=> item.product.id !== productId)
      // setCart(newCarts)
      setCart([...carts].filter((object) => object.product.id !== productId));
    }
  };

  const calculate = () => {
    setQty(carts.reduce((a, v) => (a = a + v.qty), 0));
    setPrice(
      carts.reduce((a, v) => (a = a + v.product.original_price * v.qty), 0)
    );
    setDiscount(
      carts.reduce((a, v) => (a = a + v.product.discount_amount * v.qty), 0)
    );
    setTotal(carts.reduce((a, v) => (a = a + v.product.unit_price * v.qty), 0));
  };

  const orderByWA = () => {
    if (carts.length > 0) {

      var transactionId = Date.now();
      ReactGA.plugin.require('ecommerce')

      const baseUrl = "https://api.whatsapp.com/send?";
      const phone = "6282130537608";
      let text = "Halo%20min%2C%20saya%20mau%20order%20%20%3A%20%0A";
      carts.forEach((item) => {
        const productName = encodeURIComponent(item.product.name.trim());
        text =
          text +
          item.qty +
          "x%20" +
          productName +
          "%20%3D%20Rp%20" +
          item.product.unit_price * item.qty +
          "%0A";

          ReactGA.plugin.execute('ecommerce', 'addItem', {
            id: transactionId,
            name: productName,
            sku: item.product.id,
            category: item.product.category.name,
            price: item.product.unit_price * item.qty,
            quantity: item.qty, 
            currency: 'IDR',
        });
      });
      text = text + "Total%20Harga%20%3D%20Rp%20" + total + "%0A";
      const url = baseUrl + "phone=" + phone + "&text=" + text;
      
      ReactGA.plugin.execute('ecommerce','addTransaction',{
        id: transactionId,
        affiliation: 'KIOOS',
        revenue: total,
        shipping: 0,
        tax: 0,
        currency: 'IDR',
      })

      // console.log(url);
      ReactGA.event({
        category: 'Cart',
        action: 'Order WA',
        label: 'Link : '+url
      });

      const newWindow = window.open(url, "_blank", "noopener,noreferrer");
      if (newWindow) newWindow.opener = null;


      ReactGA.plugin.execute('ecommerce', 'send');
      ReactGA.plugin.execute('ecommerce', 'clear');
    } else {
      toast({
        position: "top-right",
        title: "Keranjang Kosong",
        description: "Silahkan Isi Keranjang terlebih dahulu",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const orderByApps = () => {
    const url = "https://play.google.com/store/apps/details?id=id.kioos.kio";

    // console.log(url);
    ReactGA.event({
      category: 'Cart',
      action: 'Order APP',
      label: 'User decided to buy using apps'
    });
    
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  useEffect(() => {
    calculate();
  }, [carts]);

  return (
    <div>
      <Navbar />

      <Box px={[4, 16]} py={[2, 8]}>
        <Heading color="#F39D30">Keranjang</Heading>
      </Box>
      {/* 
      <Box border="1px solid #f2f2f2" mx={[4, 16]}>
        <Flex ml={[4, 12]} my={6} align="center">
          <Box>
            <Checkbox size="lg">Pilih Semua</Checkbox>
          </Box>
          <Spacer />
          <Box mr={[4, 12]}>
            <Text color="#B64646" fontSize={18}>
              Hapus Semua
            </Text>
          </Box>
        </Flex>
      </Box> */}

      <Box border="1px solid #f2f2f2" mx={[4, 12]} my={2}>
        {/* <Flex ml={12} mt={6} mb={6}>
                    <Box mt={2}>
                        <Checkbox size="lg"/>
                    </Box>  
                    <Box ml={4}>
                        <Text color="#F39D30" fontSize="18px" fontWeight="semibold">Warung Jajan</Text>
                        <Text>Kota Bandung</Text>
                    </Box>
                </Flex> */}

        {carts.map((item) => {
          return (
            <Box px={[2, 4]}>
              <CartItem
                data={item}
                onIncrease={() => increaseQty(item.product.id)}
                onDecrease={() => decreaseQty(item.product.id)}
                onDelete={() => {
                  setSelectedId([]);
                  selectedIds.push(item.product.id);
                  setSelectedId(selectedIds);
                  setIsOpen(true);
                }}
              />
              <Divider />
            </Box>
          );
        })}
      </Box>

      <Box border="1px solid #f2f2f2" mx={[4, 16]} my={8}>
        <Flex mx={[4, 12]} my={6} direction={{ base: "column", lg: "row" }}>
          <Box ml={[0, 4]}>
            <Table variant="unstyled" size="sm">
              <Tbody>
                <Tr>
                  <Td>Total Produk ({carts.length} Produk)</Td>
                  <Td fontWeight="semibold" fontSize={[12, 16]}>
                    {"Rp " + price}
                  </Td>
                </Tr>
                <Tr>
                  <Td>Total Diskon</Td>
                  <Td fontWeight="semibold" fontSize={[12, 16]}>
                    Rp {discount}
                  </Td>
                </Tr>
                <Tr>
                  <Td>Total Harga</Td>
                  <Td fontWeight="semibold" fontSize={[16, 20]} color="#22AFBF">
                    Rp {total}
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </Box>
          <Spacer />
          <Box mr={[0, 12]} mt={[4, 0]}>
            <Flex direction="column">
              {/* <Button bgColor="#23AFBF" color="#ffffff" mb={2}>Pesanan di antarkan</Button>
                            <Button bgColor="#F39D30" color="#ffffff" mb={2}>Ambil pesanan di tempat</Button> */}
              <Button
                bgColor="#F39D30"
                color="#ffffff"
                onClick={orderByWA}
                mb={2}
              >
                Pesan menggunakan WA
              </Button>
              <Button
                bgColor="teal"
                color="#ffffff"
                onClick={orderByApps}
                mb={2}
              >
                Pesan menggunakan Aplikasi
              </Button>
            </Flex>
          </Box>
        </Flex>
      </Box>

      <Footer />

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onDismiss}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Hapus Keranjang
            </AlertDialogHeader>

            <AlertDialogBody>
              Apakah anda yakin ingin menghapus produk di keranjang?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onDismiss}>
                Batalkan
              </Button>
              <Button colorScheme="red" onClick={onClose} ml={3}>
                Hapus
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </div>
  );
};

const CartItem = (props) => {
  const PlaceHolder = process.env.PUBLIC_URL+"/images/product-placeholder.jpg";

  const [qty, setQty] = useState(props.data.qty);
  const addQty = () => {
    props.onIncrease();
    setQty(qty + 1);
  };

  const minQty = () => {
    if (qty > 1) {
      props.onDecrease();
      setQty(qty - 1);
    }
  };

  return (
    <Flex direction={{ base: "column", lg: "row" }} mx={[4, 12]} my={8}>
      <HStack>
        {/* <Checkbox size="lg" /> */}
        <Image
          src={
            "https://backend.kioos.id/uploads/" +
            props.data.product?.default_photo?.img_path
          }
          w={{ base: 50, lg: 120 }}
          h={{ base: 50, lg: 120 }}
          mx={4}
          fallbackSrc={PlaceHolder}
        />
        <Flex direction="column" ml={4}>
          <Heading fontSize={[14, 22]} color="#22AFBF">
            {props.data.product.name}
          </Heading>

          <HStack>
            <Text
              fontSize={[14, 22]}
              mt={2}
              color="#F39D30"
              fontWeight="semibold"
            >
              {props.data.product.currency_short_form +
                " " +
                props.data.product.unit_price}
            </Text>
            {props.data.product.original_price !=
              props.data.product.unit_price && (
              <Text
                fontSize={[10, 14]}
                mt={2}
                color="gray.400"
                fontWeight="semibold"
                as="s"
              >
                {props.data.product.currency_short_form +
                  " " +
                  props.data.product.original_price}
              </Text>
            )}
          </HStack>

          <Flex direction="row" my={1}>
            <StarIcon color="#F9A53A" fontSize={[14, 22]} />
            <Text ml="2" fontSize={[12, 18]}>
              {props.data.product.overall_rating}
            </Text>
            <Box as="span" ml="2" color="gray.600" fontSize={[12, 18]}>
              Disukai {props.data.product.favourite_count}
            </Box>
            <Box as="span" ml="2" color="gray.600" fontSize={[12, 18]}>
              Terjual {props.data.product.touch_count}
            </Box>
          </Flex>
        </Flex>
      </HStack>

      <Spacer />

      <Flex
        justifyContent="space-between"
        mt={[4, 0]}
        direction={{ base: "row", lg: "column" }}
      >
        <Flex mb={4}>
          <IconButton
            onClick={minQty}
            icon={<MinusIcon fontSize={[8, 12]} />}
            isRound="true"
            mr={4}
            boxSize={[10, 12]}
          />
          <Text mt={2} fontSize={[14, 16]}>
            {qty}
          </Text>
          <IconButton
            onClick={addQty}
            icon={<AddIcon fontSize={[8, 12]} />}
            isRound="true"
            boxSize={[10, 12]}
            ml={4}
          />
        </Flex>

        <Box mt={2} align="end">
          <Text onClick={props.onDelete} color="#B64646" fontSize={[14, 18]}>
            Hapus
          </Text>
        </Box>
      </Flex>
    </Flex>
  );
};

export default Cart;
