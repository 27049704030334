import {
    Container,
    SimpleGrid,
    Image,
    Flex,
    Heading,
    Text,
    Stack,
    StackDivider,
    Icon,
    useColorModeValue,
    Button,
    Link,
  } from '@chakra-ui/react';
  import {
    IoAnalyticsSharp,
    IoCashSharp,
    IoChatbox,
    IoDesktopSharp,
    IoLaptopSharp,
    IoLogoBitcoin,
    IoMegaphoneSharp,
    IoSearchSharp,
  } from 'react-icons/io5';
  import { ReactElement } from 'react';
  
  
  const TokoImage = process.env.PUBLIC_URL+"/images/toko.jpg";
  const Feature = ({ text, icon, iconBg }) => {
    return (
      <Stack direction={'row'} align={'center'}>
        <Flex
          w={8}
          h={8}
          align={'center'}
          justify={'center'}
          rounded={'full'}
          bg={iconBg}>
          {icon}
        </Flex>
        <Text fontWeight={600}>{text}</Text>
      </Stack>
    );
  };
  
  export default function AboutStory() {
    return (
      <Container maxW={'7xl'} py={12}>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
          <Stack spacing={4}>
            <Text
              textTransform={'uppercase'}
              color={'blue.400'}
              fontWeight={600}
              fontSize={'sm'}
              bg={useColorModeValue('blue.50', 'blue.900')}
              p={2}
              alignSelf={'flex-start'}
              rounded={'md'}>
              Untuk Mitra
            </Text>
            <Heading>Berjualan digital kini menjadi semakin mudah dengan menjadi mitra kami</Heading>
            <Text color={'gray.500'} fontSize={'lg'}>
              Hanya dengan mendaftarkan warung anda bersama kami, 
              anda dapat menambah omzet anda dengan 
              menjangkau pasar yang lebih luas secara gratis.
            </Text>
            <Stack
              spacing={4}
              divider={
                <StackDivider
                  borderColor={useColorModeValue('gray.100', 'gray.700')}
                />
              }>
              <Feature
                icon={
                  <Icon as={IoCashSharp} color={'yellow.500'} w={5} h={5} />
                }
                iconBg={useColorModeValue('yellow.100', 'yellow.900')}
                text={'Biaya Pendaftaran GRATIS'}
              />
              <Feature
                icon={<Icon as={IoMegaphoneSharp} color={'green.500'} w={5} h={5} />}
                iconBg={useColorModeValue('green.100', 'green.900')}
                text={'Biaya Pemasaran GRATIS'}
              />
              <Feature
                icon={
                  <Icon as={IoDesktopSharp} color={'purple.500'} w={5} h={5} />
                }
                iconBg={useColorModeValue('purple.100', 'purple.900')}
                text={'Biaya Perangkat GRATIS'}
              />


            </Stack>
          </Stack>
          <Flex>
            <Image
              rounded={'md'}
              alt={'feature image'}
              src={
                TokoImage
              }
              objectFit={'cover'}
              h={512}
              w={712}
            />
          </Flex>
          <Stack
              spacing={{ base: 4, sm: 6 }}
              direction={{ base: 'column', sm: 'row' }}>
              <Button
                as={Link}
                href={"https://wa.me/6282130537608"} 
                target="_blank"
                rounded={'full'}
                size={'lg'}
                fontWeight={'normal'}
                px={6}
                colorScheme={'orange'}
                bg={'orange.400'}
                _hover={{ bg: 'orange.500' }}>
                Daftar Sekarang
              </Button>
              <Button
                as={Link}
                href={"https://wa.me/6282130537608"} 
                target="_blank"
                rounded={'full'}
                size={'lg'}
                fontWeight={'normal'}
                px={6}
                leftIcon={<Icon as={IoChatbox} h={4} w={4} color={'gray.300'} />}>
                Hubungi Kami
              </Button>
            </Stack>
        </SimpleGrid>
      </Container>
    );
  }