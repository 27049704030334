import { Avatar, Box, Heading, Stack, Text, useColorModeValue } from '@chakra-ui/react';

export default function WithLargeQuote() {
  const TestimoniAvatar = process.env.PUBLIC_URL+"/images/testimoni.jpg";
  return (
    <Stack
      bg={useColorModeValue('gray.50', 'gray.800')}
      py={16}
      px={8}
      spacing={{ base: 8, md: 10 }}
      align={'center'}
      direction={'column'}>
        <Stack spacing={0} align={"center"}>
          <Heading>Testimoni</Heading>
          <Text align={"center"}>Ini cerita mereka yang sudah menggunakan layanan kami</Text>
        </Stack>
      <Text
        fontSize={{ base: 'xl', md: '2xl' }}
        textAlign={'center'}
        maxW={'3xl'}>
        Berbelanja di KIOOS lebih mudah, ketika saya ada acara besar dan membutuhkan konsumsi.
        Dengan jasa KIOOS saya dapat berkonsultasi untuk kebutuhan acara saya yang sesuai dengan budget saya.
        Pengantarannya pun cepat dan barang yang kirimkan sesuai dengan yang saya inginkan. 
      </Text>
      <Box textAlign={'center'}>
        <Avatar
          src={
            TestimoniAvatar
          }
          alt={'Dian Furwadini'}
          mb={2}
        />

        <Text fontWeight={600}>Dian Furwadini</Text>
        <Text fontSize={'sm'} color={useColorModeValue('gray.400', 'gray.400')}>
          Guru Paud
        </Text>
      </Box>
    </Stack>
  );
}