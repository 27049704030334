import React from "react";
import Navbar from "./Components/Navbar";
import KatalogCard from "./Components/KatalogCard";
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Heading,
  Text,
  Image,
  IconButton,
  Button,
  Wrap,
  WrapItem,
  Avatar,
  Spacer,
  Divider,
  useAccordionContext,
  useToast,
  Icon,
  HStack,
  useBoolean,
} from "@chakra-ui/react";
import {
  ChevronRightIcon,
  StarIcon,
  AddIcon,
  MinusIcon,
} from "@chakra-ui/icons";
import { useState } from "react";
import { useEffect } from "react";
import ProductServices from "./Services/ProductServices";
import StoreServices from "./Services/StoreServices";
import ReviewServices from "./Services/ReviewServices";
import { Link, useHistory } from "react-router-dom";
import useLocalStorage from "./Tools/LocalStorage";
import { FaHeart, FaStar, FaShoppingBasket } from "react-icons/fa";
import Loading from "./Components/Loading";
import Footer from "./Components/Footer";
import ReactGA from 'react-ga';

const DetailBarang = (props) => {
  const PlaceHolder = process.env.PUBLIC_URL+"/images/product-placeholder.jpg";
  const [datas, setDatas] = useState({
    product: {},
    store: {},
    reviews: [],
    relatedProducts: [],
  });
  const [count, setCount] = useState(1);
  const [carts, setCart] = useLocalStorage("carts", []);
  const [isLoading, setIsLoading] = useBoolean();
  const history = useHistory();
  const toast = useToast();
  const countUp = () => {
    setCount(count + 1);
  };
  const countDown = () => {
    count > 0 ? setCount(count - 1) : setCount(0);
  };
  const buyNow = () => {
    if (count < 1) {
      toast({
        position: "top-right",
        title: "Kuantitas Salah",
        description: "Silahkan masukkan kuantitas dengan benar",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    

    const exist = carts.find((x) => x.product.id == datas.product.id);
    if (exist) {
      carts.map((x) =>
        x.product.id == datas.product.id ? (x.qty = exist.qty + count) : x
      );
      setCart(carts);
    } else {
      carts.push({
        product: datas.product,
        store: datas.store,
        qty: count,
      });
      setCart(carts);
    }

    ReactGA.event({
      category: 'Product',
      action: 'Buy Now',
      label: 'Product Ids : '+datas.product.id
    });

    history.push("/cart");
  };
  
  const addToCart = () => {
    if (count < 1) {
      toast({
        position: "top-right",
        title: "Kuantitas Salah",
        description: "Silahkan masukkan kuantitas dengan benar",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const exist = carts.find((x) => x.product.id == datas.product.id);
    if (exist) {
      carts.map((x) =>
        x.product.id == datas.product.id ? (x.qty = exist.qty + count) : x
      );
      setCart(carts);
    } else {
      carts.push({
        product: datas.product,
        store: datas.store,
        qty: count,
      });
      setCart(carts);
    }


    ReactGA.event({
      category: 'Product',
      action: 'Add Cart',
      label: 'Product Ids : '+datas.product.id
    });

    toast({
      position: "top-right",
      title: "berhasil menambahkan keranjang",
      description: "Produk anda telah ditambahkan ke keranjang",
      status: "success",
      duration: 5000,
      isClosable: true,
    });
  };

  let productId = props.location.state ? props.location.state.productId : "";
  let shopId = props.location.state ? props.location.state.shopId : "";
  let params = {
    shop_id: shopId,
    searchterm: "",
    order_by: "added_date",
    order_type: "desc",
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    let isActive = true;
    setIsLoading.on();

    Promise.all([
      ProductServices.getProductDetail(productId).catch((e) => {
        ReactGA.exception({
          description: 'error Detail Barang Product Detail : '+e.message,
          fatal: true
        });
        return { data: [] };
      }),
      StoreServices.getStoreDetail(shopId).catch((e) => {
        ReactGA.exception({
          description: 'error Detail Barang Store Detail : '+e.message,
          fatal: true
        });
        return { data: [] };
      }),
      ReviewServices.loadProductReviews(productId, 9, 0).catch((e) => {
        ReactGA.exception({
          description: 'error Detail Barang Product Review : '+e.message,
          fatal: true
        });
        return { data: [] };
      }),
      ProductServices.loadProducts(params, 4, 0).catch((e) => {
        ReactGA.exception({
          description: 'error Detail Barang Related Products : '+e.message,
          fatal: true
        });
        return { data: [] };
      }),
    ])
      .then(function ([product, store, reviews, relatedProducts]) {
        // console.log(
        //   "product",
        //   product.data,
        //   "store",
        //   store.data,
        //   "reviews",
        //   reviews.data,
        //   "recommends",
        //   relatedProducts.data
        // );
        if (isActive) {
          setDatas({
            product: product.data,
            store: store.data,
            reviews: reviews.data,
            relatedProducts: relatedProducts.data,
          });
        }
        setIsLoading.off();
      })
      .catch((e) => {
        // console.log(e);
        ReactGA.exception({
          description: 'error Detail Barang ALL : '+e.message,
          fatal: true
        });
        setIsLoading.off();
      });

    return () => (isActive = false);
  }, [productId, shopId]);

  return (
    <Box>
      <Navbar shopId={shopId} />
      <Loading d={isLoading ? "box" : "none"} />
      <Box d={isLoading ? "none" : "box"} w="100%">
        <Breadcrumb
          spacing="8px"
          px={[4, 20]}
          py={[4, 8]}
          separator={<ChevronRightIcon color="gray.500" />}
        >
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to="/" color="#22AFBF">
              Home
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem>
            <BreadcrumbLink
              as={Link}
              to={{
                pathname: "/kios/" + datas.store.name,
                state: {
                  photo:
                    "https://backend.kioos.id/uploads/" +
                    datas.store.default_photo?.img_path,
                  name: datas.store.name,
                  id: datas.store.id,
                  description: datas.store.description,
                  lat: datas.store.lat,
                  lng: datas.store.lng,
                  address: datas.store.address1,
                  distance: datas.store.distance_in_km,
                  email: datas.store.email,
                },
              }}
              color="#22AFBF"
            >
              {datas.store.name}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink href="#">{datas.product.name}</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Flex direction={{ base: "column", lg: "row" }} m={[0, 16]}>
          <Box d="flex" p={4}>
            <Image
              src={
                datas.product.default_photo &&
                datas.product.default_photo.img_path != ""
                  ? "https://backend.kioos.id/uploads/" +
                    datas.product.default_photo.img_path
                  : PlaceHolder
              }
              w={["100%", 300]}
              h={["50%", 300]}
            />
          </Box>
          <Box ml="8" mt={[4,0]}>
            <Heading fontSize={[20, 32]} color="#22AFBF">
              {datas.product.name}
            </Heading>

            <HStack>
              <Text
                fontSize={[20, 32]}
                mt={2}
                color="#F39D30"
                fontWeight="semibold"
              >
                {datas.product.currency_short_form +
                  " " +
                  datas.product.unit_price}
              </Text>
              {datas.product.original_price != datas.product.unit_price && (
                <Text
                  fontSize={[12, 18]}
                  mt={2}
                  color="gray.400"
                  fontWeight="semibold"
                  as="s"
                >
                  {datas.product.currency_short_form +
                    " " +
                    datas.product.original_price}
                </Text>
              )}
            </HStack>

            <Flex mt="2" align="center" mb={2}>
              <Icon
                as={FaStar}
                w={{ base: 4, lg: 5 }}
                h={{ base: 4, lg: 5 }}
                color="orange"
              />
              <Text fontSize={{ base: 11, md: 14 }} ml="2">
                {datas.product.overall_rating}
              </Text>
              <Icon
                as={FaHeart}
                d={{ base: "flex", lg: "none" }}
                ml={2}
                w={{ base: 4, lg: 4 }}
                h={{ base: 4, lg: 4 }}
                color="red"
              />
              <Text
                d={{ base: "none", lg: "flex" }}
                ml="2"
                color="gray.600"
                fontSize={["xs", "sm"]}
              >
                Disukai
              </Text>
              <Text ml="2" color="gray.600" fontSize={["xs", "sm"]}>
                {datas.product.favourite_count}
              </Text>
              <Icon
                as={FaShoppingBasket}
                d={{ base: "flex", lg: "none" }}
                ml={2}
                w={{ base: 4, lg: 4 }}
                h={{ base: 4, lg: 4 }}
                color="grey"
              />
              <Text
                d={{ base: "none", lg: "flex" }}
                ml="2"
                color="gray.600"
                fontSize={["xs", "sm"]}
              >
                Terjual
              </Text>
              <Text ml="2" color="gray.600" fontSize={["xs", "sm"]}>
                {datas.product.touch_count}
              </Text>
            </Flex>

            <Box d="flex" mt={2} alignItems="center">
              <Text mr={4} fontSize={["md", "lg"]}>
                Atur Jumlah
              </Text>
              <IconButton
                icon={<MinusIcon />}
                isRound="true"
                mr={4}
                onClick={countDown}
              />
              <Text mt={2}>{count}</Text>
              <IconButton
                icon={<AddIcon />}
                isRound="true"
                ml={4}
                onClick={countUp}
              />
            </Box>

            <Flex mt={4} pr={4} direction={["column", "row"]}>
              <Button
                onClick={() => buyNow()}
                bgColor="#23AFBF"
                color="#ffffff"
                size="lg"
                fontSize="md"
                mr={[0, 4]}
              >
                Beli Sekarang
              </Button>
              <Button
                mt={[4, 0]}
                onClick={() => addToCart()}
                bgColor="#F39D30"
                color="#ffffff"
                size="lg"
                fontSize="md"
                w="100%"
              >
                <AddIcon mr={2} /> Tambah ke keranjang
              </Button>
            </Flex>
          </Box>
        </Flex>

        <Box border="1px solid #f2f2f2" mx={[4, 16]} mt={4}>
          <Flex
            my={6}
            ml={[4, 12]}
            direction={["column", "row"]}
            justify="space-between"
          >
            <HStack>
              <Avatar
                src={
                  datas.store.default_photo
                    ? "https://backend.kioos.id/uploads/" +
                      datas.store.default_photo.img_path
                    : ""
                }
                w={[16, 24]}
                h={[16, 24]}
                mr={4}
              />
              <Box mt={4}>
                <Text
                  fontWeight="bold"
                  fontSize={["md", "2xl"]}
                  color="#F39D30"
                >
                  {datas.store.name === "" ? "Lapak Murah" : datas.store.name}
                </Text>
                <Text fontSize={["xs", "lg"]}>Kota Bandung</Text>
              </Box>
            </HStack>
            <Box align="end">
              <Button
                as={Link}
                to={{
                  pathname: "/kios/" + datas.store.name,
                  state: {
                    photo:
                      "https://backend.kioos.id/uploads/" +
                      datas.store.default_photo?.img_path,
                    name: datas.store.name,
                    id: datas.store.id,
                    description: datas.store.description,
                    lat: datas.store.lat,
                    lng: datas.store.lng,
                    address: datas.store.address1,
                    distance: datas.store.distance_in_km,
                    email: datas.store.email,
                  },
                }}
                colorScheme="teal"
                borderRadius="20"
                mt={[4, 8]}
                mr={[4, 8]}
              >
                Lihat Toko
              </Button>
            </Box>
          </Flex>
        </Box>

        <Flex direction="column" m={6} mx={[4, 16]}>
          <Heading fontSize={["lg", "3xl"]}>Deskripsi</Heading>
          <Box border="1px solid #f2f2f2" mt={6} p={8}>
            <Text>{datas.product.description}</Text>
          </Box>
        </Flex>

        <Flex direction="column" m={6} mx={[4, 16]}>
          <Heading fontSize={["lg", "3xl"]}>Daftar Ulasan</Heading>
          <Box mt="4" overflowX="auto">
            <HStack>
              <Button
                bgColor="#23AFBF"
                color="#ffffff"
                p={[8, 12]}
                pt={[4, 8]}
                pb={[4, 8]}
                borderRadius={[20, 40]}
                fontSize={["xs", "md"]}
              >
                Semua
              </Button>
              <Button
                border="2px solid #ACABAB"
                p={[8, 12]}
                pt={[4, 8]}
                pb={[4, 8]}
                borderRadius={[20, 40]}
                fontSize={["xs", "md"]}
                color="#545454"
              >
                <StarIcon color="#F39D30" mr={2} /> 5
              </Button>
              <Button
                border="2px solid #ACABAB"
                p={[8, 12]}
                pt={[4, 8]}
                pb={[4, 8]}
                borderRadius={[20, 40]}
                fontSize={["xs", "md"]}
                color="#545454"
              >
                <StarIcon color="#F39D30" mr={2} /> 4
              </Button>
              <Button
                border="2px solid #ACABAB"
                p={[8, 12]}
                pt={[4, 8]}
                pb={[4, 8]}
                borderRadius={[20, 40]}
                fontSize={["xs", "md"]}
                color="#545454"
              >
                <StarIcon color="#F39D30" mr={2} /> 3
              </Button>
              <Button
                border="2px solid #ACABAB"
                p={[8, 12]}
                pt={[4, 8]}
                pb={[4, 8]}
                borderRadius={[20, 40]}
                fontSize={["xs", "md"]}
                color="#545454"
              >
                <StarIcon color="#F39D30" mr={2} /> 2
              </Button>
              <Button
                border="2px solid #ACABAB"
                p={[8, 12]}
                pt={[4, 8]}
                pb={[4, 8]}
                borderRadius={[20, 40]}
                fontSize={["xs", "md"]}
                color="#545454"
              >
                <StarIcon color="#F39D30" mr={2} /> 1
              </Button>
            </HStack>
          </Box>
        </Flex>

        <Box m={6} mx={[4, 8]}>
          {datas.reviews.length > 0 ? (
            datas.reviews.map((item) => <ListReview data={item} />)
          ) : (
            <Text mx={[4, 8]} my={16} fontSize="xl">
              {" "}
              Tidak ada Ulasan{" "}
            </Text>
          )}
        </Box>

        <Flex direction="column" m={4} mx={[8, 16]}>
          <Heading fontSize={["lg", "3xl"]}>Produk dari toko ini</Heading>

          <Box py={[4, 8]} overflowX="scroll">
            <HStack>
              {datas.relatedProducts.map((item) => {
                return (
                  <KatalogCard
                    imageUrl={
                      "https://backend.kioos.id/uploads/" +
                      item["default_photo"]["img_path"]
                    }
                    imageAlt={item["default_photo"]["img_path"]}
                    title={item.name}
                    category={item.category.name}
                    formattedPrice={
                      item.currency_short_form + " " + item.unit_price
                    }
                    originalPrice={
                      item.currency_short_form + " " + item.original_price
                    }
                    reviewCount={item.rating_details.total_rating_count}
                    rating={item.overall_rating}
                    favorite={item.favourite_count}
                    sellingCount={item.touch_count}
                    productId={item.id}
                    shopId={item.shop_id}
                  />
                );
              })}
            </HStack>
          </Box>
        </Flex>
        <Footer />
      </Box>
    </Box>
  );
};

const ListReview = (props) => {
  // console.log(props.data);
  return (
    <Flex py={[8, 16]} px={[4, 12]}>
      <Avatar
        src={
          props.data.user.user_profile_photo
            ? "https://backend.kioos.id/uploads/" +
              props.data.user.user_profile_photo
            : ""
        }
        w={[12, 20]}
        h={[12, 20]}
      />
      <Box ml={3}>
        <Text fontWeight="bold" fontSize="md">
          {props.data.user.shipping_first_name +
            " " +
            props.data.user.shipping_last_name}
        </Text>
        <Text fontSize="sm" mt={1}>
          {props.data.added_date_str}
        </Text>
        <Text mt={1}>{props.data.description}</Text>
        <Divider mt={4} />
      </Box>

    </Flex>
  );
};

export default DetailBarang;
