import React, { useState } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbSeparator,
  HStack,
  WrapItem,
  Image,
  Box,
  Heading,
  Text,
  Avatar,
  Badge,
  Flex,
  Spacer,
  Button,
  IconButton,
  Grid,
  SimpleGrid,
  Wrap,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
  useBoolean,
  Center,
  CircularProgress,
  Icon,
} from "@chakra-ui/react";
import { ChevronRightIcon, StarIcon } from "@chakra-ui/icons";
import Navbar from "./Components/Navbar";
import Category from "./Components/Category";
import KatalogCard from "./Components/KatalogCard";
import ProductServices from "./Services/ProductServices";
import axios from "axios";
import { useEffect } from "react";
import CategoryServices from "./Services/CategoryServices";
import { Link } from "react-router-dom";
import Loading from "./Components/Loading";
import useInfiniteScroll from "./Tools/InfiniteScroll";
import { FaSearch, FaWhatsapp } from "react-icons/fa";
import Footer from "./Components/Footer";
import ReactGA from "react-ga";

const TokoPage = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [offset, setOffset] = useState(12);
  const [isEndOfScroll, setIsEndOfScroll] = useState(false);
  const [isFetching, setIsFetching] = useInfiniteScroll(loadMore);
  const [isLoading, setIsLoading] = useBoolean();
  const [isProductLoading, setIsProductLoading] = useBoolean();
  const [datas, setDatas] = useState({
    shops: {},
    categories: [],
    products: [],
  });

  const defaultShop = {
    photo: "",
    name: "KIOOS LAPAK MURAH",
    id: "shopdf2ed498619a8416d209baf1306c090f",
    description: "Lapak Murah",
    lat: 0,
    lng: 0,
    address:
      "jln. Soekarno Hatta no.590, Ruko Metro Indah Mall Blok J7, Sukajati, Buahbatu, Kota Bandung, 40286",
    distance: "0.001km",
    email: "kioos.team@gmail.com",
  };

  const onClickCategory = (id, name) => {
    setParams({ ...params, cat_id: id });

    ReactGA.event({
      category: 'Store',
      action: 'Click on Category',
      value: params.shop_id+"_"+id
    });

  };

  const [params, setParams] = useState({
    shop_id: props.location.state ? props.location.state.id : defaultShop.id,
    searchterm: props.location.state ? props.location.state.search : "",
    order_by: "added_date",
    order_type: "desc",
    cat_id: "0",
  });

  const loadFirst = () => {
    setDatas({
      shops: {},
      categories: [],
      products: [],
    });
    setOffset(12);
    Promise.all([
      ProductServices.loadProducts(params, 12, 0).catch((e) => {
        ReactGA.exception({
          description: 'Error Load Product : '+e.message,
          fatal: true
        });
        return { data: [] };
      }),
      CategoryServices.loadAllCategories(0, 0).catch((e) => {
        ReactGA.exception({
          description: 'Error Load Category : '+e.message,
          fatal: true
        });
        return { data: [] };
      }),
    ])
      .then(function ([product, category]) {
        // console.log("product", product.data, "category", category.data);
        setDatas({
          shops: props.location.state ? props.location.state : defaultShop,
          categories: category.data,
          products: product.data,
        });
        setIsEndOfScroll(false);
        setIsFetching(false);
        setIsLoading.off();
      })
      .catch((e) => {
        // console.log(e);
        ReactGA.exception({
          description: 'Error Load First Product at Store '+params.shop_id+' category '+params.cat_id+' : '+e.message,
          fatal: true
        });

        setIsLoading.off();
      });
  };

  function loadMore() {
    if (offset % 12 === 0 && !isEndOfScroll) {
      // console.log("isi datas => ");
      // console.log(datas);
      ProductServices.loadProducts(params, 12, offset)
        .then((res) => {
          setDatas({ ...datas, products: [...datas.products, ...res.data] });
          setOffset(datas.products.length + 12);
          setIsFetching(false);
        })
        .catch((e) => {
          ReactGA.exception({
            description: 'Error Load More Product at Store '+params.shop_id+' category '+params.cat_id+' : '+e.message,
            fatal: true
          });
  
          setIsFetching(false);
          setIsEndOfScroll(true);
        });
    } else {
      // console.log("end of scroll");
      setIsFetching(false);
      setIsEndOfScroll(true);
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    setIsLoading.on();
    loadFirst();
  }, [params]);

  const FilledList = (props) => {
    return (
      <Flex py={[4, 8]} pl={{ base: 4, lg: 20 }} direction="column">
        <Wrap justify="space-evenly" spacing={[2, 4]} w="95%">
          {datas.products.map((item) => {
            return (
              <WrapItem>
                <KatalogCard
                  imageUrl={
                    "https://backend.kioos.id/uploads/" +
                    item["default_photo"]["img_path"]
                  }
                  imageAlt={item["default_photo"]["img_path"]}
                  title={item.name}
                  category={item.category.name}
                  formattedPrice={
                    item.currency_short_form + " " + item.unit_price
                  }
                  originalPrice={
                    item.currency_short_form + " " + item.original_price
                  }
                  reviewCount={item.rating_details.total_rating_count}
                  rating={item.overall_rating}
                  favorite={item.favourite_count}
                  sellingCount={item.touch_count}
                  productId={item.id}
                  shopId={item.shop_id}
                />
              </WrapItem>
            );
          })}
        </Wrap>
        {isFetching && !isEndOfScroll && (
          <Center mt={[4, 8]}>
            <CircularProgress isIndeterminate color="blue.300" />
          </Center>
        )}
      </Flex>
    );
  };

  const EmptyList = (props) => {
    return (
      <Flex
        py={[4, 8]}
        pl={{ base: 4, lg: 20 }}
        align="center"
        direction="column"
      >
        <Icon
          as={FaSearch}
          w={[12, 16]}
          h={[12, 16]}
          mb={[4, 8]}
          color="gray.300"
        />
        <Heading size="md" align="center">
          Maaf, barang yang anda cari tidak ada.
        </Heading>
        <Text align="center" mt={2} mb={[4, 8]} mx={[4, 8]}>
          Silahkan hubungi kami untuk mendapatkan barang yang anda inginkan
        </Text>
        <a href="https://wa.me/6282130537608" target="_blank">
          <Button leftIcon={<FaWhatsapp />} colorScheme="teal" size="md">
            Kontak Kami
          </Button>
        </a>
      </Flex>
    );
  };

  return (
    <Box>
      <Navbar d={isOpen ? "none" : "flex"} shopId={params.shop_id} />
      <Loading d={isLoading ? "box" : "none"} />
      <Box d={isLoading ? "none" : "box"} w="100%">
        <Breadcrumb
          size="sm"
          spacing="8px"
          px={[4, 20]}
          py={[4, 8]}
          separator={<ChevronRightIcon color="gray.500" />}
        >
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to="/" color="#22AFBF">
              Home
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to="#">
              {datas.shops.name}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Flex px={[4, 20]} align="center" direction={["column", "row"]}>
          <Avatar
            src={datas.shops.photo}
            w={[16, 28]}
            h={[16, 28]}
            mr={[4, 8]}
          />

          <Box ml="3" mt={[4, 0]}>
            <Text fontWeight="bold" fontSize={["md", "2xl"]}>
              {datas.shops.name}
              <Badge
                ml="1"
                colorScheme="green"
                borderRadius="md"
                variant="solid"
                p="1"
                fontSize={["xx-small", "xs"]}
              >
                Buka
              </Badge>
            </Text>
            <Text fontSize={["md", "lg"]}>Kota Bandung</Text>
          </Box>
          <Spacer />
          <Box mt={[4, 0]}>
            <Button colorScheme="teal" borderRadius="20" onClick={onOpen}>
              Lihat Detail Toko
            </Button>

            <Modal 
              size="5xl"
              scrollBehavior="outside" isOpen={isOpen} onClose={onClose}>
              <ModalOverlay />
              <ModalContent paddingBottom={4}>
                <ModalHeader>Detail Toko</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Flex align={"center"} direction={["column", "row"]}>
                    <Avatar src={datas.shops.photo} size="lg" />
                    <Box ml="3">
                      <Text fontWeight="bold" fontSize={["md", "lg"]}>
                        {datas.shops.name}
                        <Badge
                          ml="1"
                          colorScheme="green"
                          borderRadius="md"
                          variant="solid"
                          p="1"
                          fontSize={["xx-small", "xs"]}
                        >
                          Buka
                        </Badge>
                      </Text>
                      <Text fontSize={["md", "lg"]}>Kota Bandung</Text>
                    </Box>
                    <Spacer />
                    {/* <Box d="flex" mt={1}>
                    <StarIcon color="#F9A53A" mt="2" mr="2" />
                    <Text fontSize="xl">4.5</Text>
                  </Box> */}
                  </Flex>
                  <Box mt={4}>
                    <Heading size="sm" fontWeight="semibold">
                      Deskripsi
                    </Heading>
                    <Text mt={2}>{datas.shops.description}</Text>
                  </Box>
                  <Box mt={4}>
                    <Heading size="sm" fontWeight="semibold">
                      Alamat
                    </Heading>
                    <Text mt={2}>{datas.shops.address}</Text>
                  </Box>
                  {/* <Box mt={4}>
                        <Heading size="sm" fontWeight="semibold">Jam Buka Tutup</Heading>
                        <Text mt={2}>Senin - Jumat  09:00 - 17:00 WIB</Text>
                        <Text mt={2}>Sabtu & Minggu  09:00 - 15:00 WIB</Text>
                    </Box> */}
                </ModalBody>
              </ModalContent>
            </Modal>
          </Box>
        </Flex>

        <Flex
          mt={{ base: 8, lg: 16 }}
          mb={4}
          justify="space-between"
          alignItems="center"
        >
          <Box pl={{ base: 4, lg: 20 }}>
            <Heading as="h2" fontSize={["md", "2xl"]}>
              Kategori
            </Heading>
          </Box>
        </Flex>

        <Box
          overflowX="auto"
          spacing="30px"
          mb="20px"
          mx={{ base: 4, lg: 20 }}
          mt="4px"
          w="90%"
          sx={{"::-webkit-scrollbar":{
            height: "10px",
            width: "10px"
          }}}
        >
          <HStack px={{ base: 4, lg: 8 }} spacing={{ base: 2, lg: 8 }}>
            {datas.categories.map((item) => {
              // console.log(item)
              return (
                <Category
                  onClick={(e) => onClickCategory(item.id, item.name)}
                  item={item}
                />
              );
            })}
          </HStack>
        </Box>

        <Flex
          mt={{ base: 8, lg: 16 }}
          mb={4}
          justify="space-between"
          alignItems="center"
        >
          <Box pl={{ base: 4, lg: 20 }}>
            <Heading as="h2" fontSize={["md", "2xl"]}>
              Daftar Produk
            </Heading>
          </Box>
        </Flex>

        {datas.products.length > 0 ? <FilledList /> : <EmptyList />}
        <Footer/>
      </Box>
    </Box>
  );
};

export default TokoPage;
