import { ReactElement } from "react";
import {
  Box,
  SimpleGrid,
  Icon,
  Text,
  Stack,
  Flex,
  Container,
  Heading,
  useColorModeValue,
} from "@chakra-ui/react";
import { FcAssistant, FcCollaboration, FcCustomerSupport, FcDecision, FcDonate, FcInTransit, FcPackage, FcShop } from "react-icons/fc";

const Feature = ({ title, text, icon }) => {
  return (
    <Stack>
      <Flex
        w={16}
        h={16}
        align={"center"}
        justify={"center"}
        color={"white"}
        rounded={"full"}
        bg={"gray.100"}
        mb={1}
      >
        {icon}
      </Flex>
      <Text fontWeight={600}>{title}</Text>
      <Text color={"gray.600"}>{text}</Text>
    </Stack>
  );
};

export default function AboutFeature() {
  return (
    <Box bg={useColorModeValue("gray.100", "gray.700")}>
      <Container maxW={"7xl"} py={12} as={Stack} spacing={12}>
        <Stack spacing={0} align={"center"}>
          <Heading>Fitur Unggulan</Heading>
          <Text align={"center"}>Kami menyediakan berbagai fitur unggulan khusus untuk anda</Text>
        </Stack>

        <Box p={4}>
          <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10}>
            <Feature
              icon={<Icon as={FcShop} w={10} h={10} />}
              title={"Warung Terdekat"}
              text={
                "Anda dapat menemukan informasi berbagai warung di sekitar anda dengan aplikasi kami"
              }
            />
            <Feature
              icon={<Icon as={FcPackage} w={10} h={10} />}
              title={"Stok Terjamin"}
              text={
                "Kami menjamin barang yang kami tampilkan selalu asli dan selalu tersedia."
              }
            />
            <Feature
              icon={<Icon as={FcDecision} w={10} h={10} />}
              title={"Pemesanan Khusus"}
              text={
                "Anda dapat melakukan pemesanan khusus untuk mencari barang sesuai budget."
              }
            />
            <Feature
              icon={<Icon as={FcInTransit} w={10} h={10} />}
              title={"Pengantaran Cepat"}
              text={
                "Kami menyediakan pengantaran dengan cepat dan aman dalam hitungan menit"
              }
            />
            <Feature
              icon={<Icon as={FcDonate} w={10} h={10} />}
              title={"Ambil Sendiri"}
              text={
                "Anda dapat memesan dari rumah dan mengambilnya sendiri ke warung"
              }
            />
            <Feature
              icon={<Icon as={FcCustomerSupport} w={10} h={10} />}
              title={"Layanan 24x7"}
              text={
                "Ketika anda mengalami masalah kami selalu siap membantu anda"
              }
            />
          </SimpleGrid>
        </Box>
      </Container>
    </Box>
  );
}
