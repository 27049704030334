import React, { Component } from 'react'
import {
    Grid, Box, Image, VStack, FormControl, FormLabel, Input,
    FormHelperText, Text, Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
} from '@chakra-ui/react'
import AuthServices from './Services/AuthServices'
import ReactGA from 'react-ga'

class Register extends Component {
    constructor(props) {
        super(props)

        this.state = {
            user_email: '',
            user_password: '',
            user_name: '',
            user_phone: ''
        }

        this.doRegister = this.doRegister.bind(this);
        this.moveLogin = this.moveLogin.bind(this);
        this.changeEmailHandler = this.changeEmailHandler.bind(this);
        this.changeNameHandler = this.changeNameHandler.bind(this);
        this.changePhoneHandler = this.changePhoneHandler.bind(this);
        this.changePasswordHandler = this.changePasswordHandler.bind(this);

    }

    doRegister = (e) => {
        let account = {
            user_email: this.state.user_email,
            user_password: this.state.user_password,
            user_name: this.state.user_name,
            user_phone: this.state.user_phone,
        }
        // console.log('account => ' + JSON.stringify(account));
        ReactGA.event({
            category: 'Login',
            action: 'Register with Email ',
            value: account.user_email
          });

        AuthServices.doRegister(account).then(res => {
            // alert('Berhasil Register');
            this.props.history.push({
                pathname: '/verifikasi',
                state: {
                    userId: res.data.user_id
                }
            });
        }).catch( error => {
            if( error.response ){
                // console.log(error.response.data);
                alert(error.response.data.message);

                ReactGA.exception({
                    description: 'Error Register : '+error.message,
                    fatal: true
                  });

            }
        })
    }

    moveLogin = (e) => {
        this.props.history.goBack();
    }

    changeEmailHandler = (event) => {
        this.setState({ user_email: event.target.value });
    }

    changePasswordHandler = (event) => {
        this.setState({ user_password: event.target.value });
    }

    changeNameHandler = (event) => {
        this.setState({ user_name: event.target.value });
    }

    changePhoneHandler = (event) => {
        this.setState({ user_phone: event.target.value });
    }


    
     render() {
        return (
            <Grid templateColumns="repeat(2, 1fr)" gap={0}>
                <Box width="100%" bg="#22AFBF">
                    <Image src={process.env.PUBLIC_URL+"/images/kio.png"} w="60%" pos="relative" left="20%" top="20%" />
                </Box>
                <Box width="100%" height={658} bg="white">
                    <VStack spacing="15px">
                        <Image src={process.env.PUBLIC_URL+"/images/logo.png"} w="20%" mt="16" />
                        <Box boxShadow="md" p="6" rounded="md" bg="white" w="60%">
                            <FormControl id="namalengkap" mb="4">
                                <FormLabel>Nama Lengkap</FormLabel>
                                <Input type="text" value={this.state.user_name} onChange={this.changeNameHandler}  />
                            </FormControl>

                            <FormControl id="email" mb="4">
                                <FormLabel>Email</FormLabel>
                                <Input type="email" value={this.state.user_email} onChange={this.changeEmailHandler}  />
                            </FormControl>

                            <FormControl id="notel" mb="4">
                                <FormLabel>Nomor Telepon</FormLabel>
                                <Input type="text" value={this.state.user_phone} onChange={this.changePhoneHandler}  />
                            </FormControl>

                            <FormControl id="pass" mb="4">
                                <FormLabel>Kata Sandi</FormLabel>
                                <Input type="password" value={this.state.user_password} onChange={this.changePasswordHandler}  />
                            </FormControl>

                            <Button colorScheme="teal" size="md" w="100%" mt={4} onClick={this.doRegister}>
                                Daftar
                            </Button>
                        </Box>
                    </VStack>
                    <Text textAlign="center" fontSize="md" mt={4}>Sudah Punya Akun ?</Text>
                    <Text textAlign="center" fontSize="md" color="#22AFBF" fontWeight="bold" mb={8} onClick={this.moveLogin}>Login</Text>
                </Box>
            </Grid>
        )
    }
}

export default Register
