import axios from 'axios';
import React from 'react';

const BASE_URL = "https://backend.kioos.id";
const API_KEY = "mitrawarungkuwkwk";

class AuthServices {

    doLogin(account){
        // axios.defaults.headers.post['Content-Type'] ='application/json';
        axios.defaults.headers.post['Access-Control-Allow-Origin'] = "*";
        axios.defaults.headers.post['Content-Type'] = "multipart/form-data;";
        var url = BASE_URL + "/rest/users/login/api_key/"+ API_KEY;
        return axios.post(url, account);
    }

    doRegister(account){
        axios.defaults.headers.post['Access-Control-Allow-Origin'] = "*";
        axios.defaults.headers.post['Content-Type'] = "multipart/form-data;";
        var url = BASE_URL + "/rest/users/add/api_key/"+ API_KEY;
        return axios.post(url, account);
    }

    doVerify(userId, code){
        let verify = {
            user_id: userId,
            code: code
        }
        axios.defaults.headers.post['Access-Control-Allow-Origin'] = "*";
        axios.defaults.headers.post['Content-Type'] = "multipart/form-data;";
        var url = BASE_URL + "/rest/users/verify/api_key/"+ API_KEY;
        return axios.post(url, verify);
    }

    doLoginFetch(account){
        const formData = new FormData();
        formData.append('user_email', account.user_email);
        formData.append('user_password', account.user_password);
        
        var requestOptions = {
            method: 'POST',
            body: formData,
            mode: 'cors', 
            crossDomain:true,
            redirect: 'follow'
        };

        var url = BASE_URL + "/rest/users/login/api_key/"+ API_KEY;
        
        return fetch(url, requestOptions)
    }

}

export default new AuthServices()