import React from 'react'
import Login from './Login'
import Register from './Register'
import Home from './Home'
import Notification from './Notification'
import TokoPage from './TokoPage'
import DetailBarang from './DetailBarang'
import Cart from './Cart'
import Checkout from './Checkout'

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import HasilPencarian from './HasilPencarian'
import DetailBanner from './DetailBanner'
import Verification from './Verification'
import NotFound from './NotFound'
import About from './About'

import ReactGA from 'react-ga';
import useLocalStorage from './Tools/LocalStorage'
import withTracker from './Tools/WithTracker'

function App() {
  const [userData, setUserData] = useLocalStorage("user", {
    id: "",
    name: "",
    photo: "",
  });
  const nowDate = Date.now();

  if(userData.id == "") setUserData({
    id: nowDate,
    name: "",
    photo: "",
  })

  const trackingId = "UA-203744230-1";
  ReactGA.initialize(trackingId);
  ReactGA.set({
    userId: nowDate
  })

  return (
    <div>
      <Router>
        <div className="container">
          <Switch>
            <Route path="/" exact component={withTracker(Home)}></Route>
            <Route path="/login" component={withTracker(Login)}></Route>
            <Route path="/register" component={withTracker(Register)}></Route>
            <Route path="/banner/:id" component={withTracker(DetailBanner)}></Route>
            <Route path="/kios/:name" component={withTracker(TokoPage)}></Route>
            <Route path="/barang/:id" component={withTracker(DetailBarang)}></Route>
            <Route path="/cari" component={withTracker(HasilPencarian)}></Route>
            <Route path="/cart" component={withTracker(Cart)}></Route>
            <Route path="/checkout" component={withTracker(Checkout)}></Route>
            <Route path="/verifikasi" component={withTracker(Verification)}></Route>
            <Route path="/notifikasi" component={withTracker(Notification)}></Route>
            <Route path="/about" component={withTracker(About)}></Route>
            <Route component={NotFound}></Route>
            {/* <Route path = "/update-employee/:id" component = {UpdateEmployeeComponent}></Route> */}
          </Switch>
        </div>
      </Router>

    </div>
  );
}

export default App;
