import axios from 'axios';
import React from 'react';

const BASE_URL = "https://backend.kioos.id";
const API_KEY = "mitrawarungkuwkwk";

class BannerServices {

    loadAllBanner(limit, offset){
        // axios.defaults.headers.post['Content-Type'] ='application/json';
        axios.defaults.headers.post['Access-Control-Allow-Origin'] = "*";
        var url = BASE_URL + "/rest/feeds/get/api_key/"+ API_KEY+"/limit/"+limit+"/offset/"+offset;
        return axios.get(url);
    }

}

export default new BannerServices()