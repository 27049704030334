import React from "react";
import { Component } from "react";
import Navbar from "./Components/Navbar";
import KatalogCard from "./Components/KatalogCard";
import {
  Box,
  Avatar,
  Flex,
  Wrap,
  WrapItem,
  Text,
  Button,
  Image,
  Input,
  IconButton,
  useDisclosure,
  VStack,
  Heading,
  Spacer,
  Select,
  Center,
  CircularProgress,
  Icon,
  Link,
  useBoolean,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from "@chakra-ui/react";
import { ChevronRightIcon, ChevronLeftIcon } from "@chakra-ui/icons";
import { useState } from "react";
import { useEffect } from "react";
import ProductServices from "./Services/ProductServices";
import useInfiniteScroll from "./Tools/InfiniteScroll";
import { FaSearch, FaWhatsapp } from "react-icons/fa";
import Loading from "./Components/Loading";
import Footer from "./Components/Footer";
import {Link as RouterLink} from "react-router-dom";
import ReactGA from 'react-ga';
import useLocalStorage from "./Tools/LocalStorage";

const HasilPencarian = (props) => {
  const [product, setProduct] = useState([]);
  const [offset, setOffset] = useState(12);
  const [isEndOfScroll, setIsEndOfScroll] = useState(false);
  const [isFetching, setIsFetching] = useInfiniteScroll(loadMore);
  const [isLoading, setIsLoading] = useBoolean();
  const [location, setLocation] = useLocalStorage({
    lat: 0,
    lng: 0,
  });

  const searchKey =
    props.location.state != undefined ? props.location.state.key : "";
  const searchCategory =
    props.location.state != undefined ? props.location.state.categoryId : "";

  let params = {
    searchterm: searchCategory == "" ? searchKey : "",
    cat_id: searchCategory != "" ? searchCategory : "",
    order_by: "added_date",
    order_type: "desc",
    loc_lat: 0,
    loc_lng: 0,
  };

  useEffect(() => {
    if(location != undefined && location.lat !== 0 && location.lng !== 0){
      loadFirstItemWithLocation(
        location.lat,
        location.lng
      );
    } else if ("geolocation" in navigator) {
      // console.log("Location Available");
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // console.log("Location Latitude is :", position.coords.latitude);
          // console.log("Location Longitude is :", position.coords.longitude);
          loadFirstItemWithLocation(
            position.coords.latitude,
            position.coords.longitude
          );
          setLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        (error) => {
          // console.log("Error Code = " + error.code + " - " + error.message);
          ReactGA.exception({
            description: 'Error Load Location on Search : '+error.message,
            fatal: true
          });
  
          loadFirstItemWithoutLocation();
        }
      );
    } else {
      console.log("Location Not Available");
      loadFirstItemWithoutLocation();
    }
  }, [searchKey]);

  function loadMore() {
    if (offset % 12 === 0 && !isEndOfScroll) {
      if (location.lat === 0 && location.lng === 0) {
        ProductServices.loadProducts(params, 12, offset)
          .then((res) => {
            setProduct((prev) => [...prev, ...res.data]);
            setOffset(product.length + 12);
            setIsFetching(false);
          })
          .catch((e) => {
            ReactGA.exception({
              description: 'Error Fetching More Product '+params.searchterm+'['+offset+'] : '+e.message,
              fatal: true
            });
            setIsFetching(false);
            setIsEndOfScroll(true);
          });
      } else {
        params.loc_lat = location.lat;
        params.loc_lng = location.lng;

        ProductServices.loadNearestProducts(params, 12, offset)
          .then((res) => {
            setProduct((prev) => [...prev, ...res.data]);
            setOffset(product.length + 12);
            setIsFetching(false);
          })
          .catch((e) => {
            ReactGA.exception({
              description: 'Error Fetching More Product '+params.searchterm+'['+offset+'] : '+e.message,
              fatal: true
            });
            setIsFetching(false);
            setIsEndOfScroll(true);
          });
      }
    } else {
      // console.log("end of scroll");
      setIsEndOfScroll(true);
      setIsFetching(false);
    }
  }

  function loadFirstItemWithoutLocation() {
    setIsLoading.on();
    setProduct([]);
    setOffset(12);
    ProductServices.loadProducts(params, 12, 0)
      .then((res) => {
        setProduct(res.data);
        setIsEndOfScroll(false);
        setIsFetching(false);
        setIsLoading.off();
      })
      .catch((e) => {
        ReactGA.exception({
          description: 'Error Load First Product '+params.searchterm+' : '+e.message,
          fatal: true
        });

        // console.log(e);
        setIsLoading.off();
      });
  }

  function loadFirstItemWithLocation(lat, lng) {
    setIsLoading.on();
    setProduct([]);
    setOffset(12);
    params.loc_lat = lat;
    params.loc_lng = lng;

    ProductServices.loadNearestProducts(params, 12, 0)
      .then((res) => {
        setProduct(res.data);
        setIsEndOfScroll(false);
        setIsFetching(false);
        setIsLoading.off();
      })
      .catch((e) => {
        ReactGA.exception({
          description: 'Error Load First Product '+params.searchterm+' : '+e.message,
          fatal: true
        });

        // console.log(e);
        setIsLoading.off();
      });
  }

  const EmptyList = (props) => {
    return (
      <Flex
        py={[4, 8]}
        pl={{ base: 4, lg: 20 }}
        align="center"
        direction="column"
      >
        <Icon
          as={FaSearch}
          w={[12, 16]}
          h={[12, 16]}
          mb={[4, 8]}
          color="gray.300"
        />
        <Heading size="md" align="center">
          Maaf, Barang yang anda cari tidak ada.
        </Heading>
        <Text align="center" mt={2} mb={[4, 8]} mx={[4, 8]}>
          Silahkan hubungi kami untuk mendapatkan barang yang anda inginkan
        </Text>
        <Button
          as={Link}
          leftIcon={<FaWhatsapp />}
          href="https://wa.me/6282130537608"
          target="_blank"
          colorScheme="teal"
          size="md"
        >
          Kontak Kami
        </Button>
      </Flex>
    );
  };

  const FilledList = (props) => {
    return (
      <Flex py={[4, 8]} pl={{ base: 4, lg: 20 }} direction="column">
        <Wrap justify="space-evenly" spacing={[2, 4]} w="95%">
          {product.map((item) => {
            return (
              <WrapItem>
                <KatalogCard
                  imageUrl={
                    item.default_photo != undefined
                      ? "https://backend.kioos.id/uploads/" +
                        item.default_photo.img_path
                      : ""
                  }
                  imageAlt={
                    item.default_photo != undefined
                      ? item.default_photo.img_path
                      : ""
                  }
                  title={item.name}
                  category={item.category?.name}
                  formattedPrice={
                    item.currency_short_form + " " + item.unit_price
                  }
                  originalPrice={
                    item.currency_short_form + " " + item.original_price
                  }
                  reviewCount={item.rating_details?.total_rating_count}
                  rating={item.overall_rating}
                  favorite={item.favourite_count}
                  sellingCount={item.touch_count}
                  productId={item.id}
                  shopId={item.shop_id}
                />
              </WrapItem>
            );
          })}
        </Wrap>
        {!isEndOfScroll ? (
          isFetching ? (
            <Center mt={[4, 8]}>
              <CircularProgress isIndeterminate color="blue.300" />
            </Center>
          ) : (
            <div />
          )
        ) : (
          <div />
        )}
      </Flex>
    );
  };

  return (
    <Box>
      <Navbar />
      <Loading d={isLoading ? "box" : "none"} />
      <Box d={isLoading ? "none" : "box"}>
        <Breadcrumb
          size="sm"
          spacing="8px"
          px={[4, 16]}
          py={[4, 8]}
          separator={<ChevronRightIcon color="gray.500" />}
        >
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to="/" color="#22AFBF">
              Home
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to="#">
              Pencarian
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Box px={[4, 16]} pb={8}>
          <Heading fontSize={[20, 32]}>
            Hasil Pencarian untuk "{searchKey}"
          </Heading>
        </Box>

        {/* <Flex border="1px solid #f2f2f2" px={[4,16]} py={[4,8]} mx={[4,24]}>
                    <Box mt={2}>
                        <Text fontWeight="bold" fontSize="lg" color="#545454">
                            Urutkan
                        </Text>
                    </Box>
                    <Spacer />
                    <Box>
                        <Select size="md" w={500}>
                            <option value="terkait">Terkait</option>
                            <option value="terlaris">Terlaris</option>
                            <option value="termurah">Termurah</option>
                            <option value="termahal">Termahal</option>
                        </Select>
                    </Box>
                    <Spacer />
                    <Box d="flex" mr={24}>
                        <Text fontSize={24} mr={16}>1/10</Text>
                        <IconButton icon={<ChevronLeftIcon />} mr={4} isRound="true" />
                        <IconButton icon={<ChevronRightIcon />} isRound="true" bgColor="#22AFBF" color="#ffffff" />
                    </Box>
            </Flex> */}

        {product.length > 0 ? <FilledList /> : <EmptyList />}
        <Footer />
      </Box>
    </Box>
  );
};

export default HasilPencarian;
