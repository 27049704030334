import axios from 'axios';
import React from 'react';

const BASE_URL = "https://backend.kioos.id";
const API_KEY = "mitrawarungkuwkwk";

class CategoryServices {

    loadAllCategories(limit, offset){
        // axios.defaults.headers.post['Content-Type'] ='application/json';
        axios.defaults.headers.post['Access-Control-Allow-Origin'] = "*";
        var url = BASE_URL + "/rest/categories/search/api_key/"+ API_KEY+"/limit/"+limit+"/offset/"+offset;
        return axios.post(url);
    }

}

export default new CategoryServices()