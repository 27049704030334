import React from 'react'
import Navbar from './Components/Navbar'
import { ArrowBackIcon, StarIcon, AddIcon, MinusIcon } from '@chakra-ui/icons'
import { Link, Image, Checkbox, IconButton, Box, Flex, Spacer, Heading, Text, Table, Tbody, Tr, Td, Button } from "@chakra-ui/react"

const Checkout = () => {
    return (
        <div>
            <Navbar/>
            <Box m={16} mb={8}>
                <Link color="teal.500" href="http://localhost:3000/cart"><ArrowBackIcon/> Kembali ke keranjang</Link>
                <Heading color="#F39D30">Pesanan di antarkan</Heading>
                <Text>Silakan lengkapi alamat pengiriman kamu dan pilih pembayaran sebelum melanjutkan</Text>
            </Box>
            
            <Box border="1px solid #f2f2f2" ml={16} mr={24}>
            <Flex ml={12} mt={6} mb={6}>
                <Box mt={2}>
                    <Text color="#22AFBF" fontWeight="semibold">Alamat Pengiriman</Text>
                    <Text fontWeight="semibold">Mukhlis Pardi (Rumah)</Text>
                    <Text>6281231831731</Text>
                    <Text>Jl. Tanjung Sari VII No. 10, Antapani, Kota Bandung, 40291</Text>
                </Box>
                <Spacer/>
                <Box mt={2} mr={16}>
                    <Button bgColor="#F39D30" color="#ffffff">Pilih Alamat Lain</Button>
                </Box>    
            </Flex>
            </Box>

            <Box border="1px solid #f2f2f2" ml={16} mr={24} mt={8} mb={8}>
                <Flex ml={12} mt={6} mb={6}>
                    <Box ml={4}>
                        <Text color="#F39D30" fontSize="18px" fontWeight="semibold">Warung Jajan</Text>
                        <Text>Kota Bandung</Text>
                    </Box>
                </Flex>
                <Box d="flex" ml={12} mb={8}>
                    <Image src="https://bit.ly/sage-adebayo" w="120px" h="120px" ml={4}/>
                    <Flex direction="column" ml={4}>
                        <Heading fontSize={22} color="#22AFBF">Beras Merah 1 KG</Heading>
                        <Text fontSize={22} mt={2} color="#F39D30" fontWeight="semibold">Rp 21.000</Text>
                        
                        <Flex direction="row">
                            <StarIcon color="#F9A53A" fontSize={22}/>
                            <Text ml="2" fontSize={18}>4</Text>
                            <Box as="span" ml="2" color="gray.600" fontSize={18}>
                                Qty: 2
                            </Box>
                        </Flex>
                    </Flex> 
                </Box>
                
                <Box d="flex" ml={12} mb={8}>
                    <Image src="https://bit.ly/sage-adebayo" w="120px" h="120px" ml={4}/>
                    <Flex direction="column" ml={4}>
                        <Heading fontSize={22} color="#22AFBF">Beras Merah 1 KG</Heading>
                        <Text fontSize={22} mt={2} color="#F39D30" fontWeight="semibold">Rp 21.000</Text>
                        
                        <Flex direction="row">
                            <StarIcon color="#F9A53A" fontSize={22}/>
                            <Text ml="2" fontSize={18}>4</Text>
                            <Box as="span" ml="2" color="gray.600" fontSize={18}>
                                Qty: 2
                            </Box>
                        </Flex>
                    </Flex>
                </Box>
            </Box>


            <Box border="1px solid #f2f2f2" ml={16} mr={24} mt={8} mb={8}>
                <Flex ml={12} mt={6} mb={6}>
                    <Box ml={4}>
                        <Table variant="unstyled" size="sm">
                            <Tbody>
                                <Tr>
                                    <Td>Total Produk (4 Produk)</Td>
                                    <Td fontWeight="semibold">Rp 170.000</Td>
                                </Tr>
                                <Tr>
                                    <Td>Total Diskon</Td>
                                    <Td fontWeight="semibold">Rp 0</Td>
                                </Tr>
                                <Tr>
                                    <Td>Total Harga</Td>
                                    <Td fontWeight="semibold" fontSize="22px" color="#22AFBF">Rp 170.000</Td>
                                </Tr>
                            </Tbody>
                        </Table>
                    </Box>
                    <Spacer/>
                    <Box mr={12}>
                        <Flex direction="column">
                            <Button bgColor="#23AFBF" color="#ffffff" mb={2}>Pesanan di antarkan</Button>
                            <Button bgColor="#F39D30" color="#ffffff">Ambil pesanan di tempat</Button>
                        </Flex>
                    </Box>
                </Flex>
            </Box>
        </div>
    )
}
export default Checkout
