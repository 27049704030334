import React from "react";
import { Link, useHistory } from "react-router-dom";
import {
  Box,
  Stack,
  Flex,
  Text,
  Button,
  Image,
  Input,
  Icon,
  IconButton,
  useDisclosure,
  Avatar,
  Divider,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from "@chakra-ui/react";
import { FiShoppingCart, FiHome, FiArrowRight, FiSearch } from "react-icons/fi";
import {
  HamburgerIcon,
  SearchIcon,
  BellIcon,
  CloseIcon,
  ArrowRightIcon,
} from "@chakra-ui/icons";
import { useState } from "react";
import { useEffect } from "react";
import useLocalStorage from "../Tools/LocalStorage";
import ReactGA from "react-ga";

const logo = process.env.PUBLIC_URL+"/images/logo.png";
const Navbar = ({shopId,props}) => {
  let history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const [isFocus, setIsFocus] = useState(false);
  const handleToggle = () => setIsOpen(!isOpen);
  const [search, setSearch] = useState("");
  const [userData, setUserData] = useLocalStorage("user", {
    id: "",
    name: "",
    photo: "",
  });
  // console.log(userData);
  const [isLogin, setIsLogin] = useLocalStorage("isLogin", false);
  const onSignIn = () => {
    history.push("/login");
  };
  const onLogout = () => {
    ReactGA.event(userData)
    setUserData({
      id: "",
      name: "",
      photo: "",
    });
    setIsLogin(false);
    history.push("/");
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
        doSearch()
    }
  };

  const doSearch = (event) => {
    ReactGA.event({
      category: 'Search',
      action: 'Search Product',
      label: search
    });

    history.push({
      pathname: "/cari",
      state: {
        key: search,
        categoryId: "",
      },
    });
  }

  useEffect(() => {
    if (isFocus && search.length == 0) {
      setIsFocus(false);
    }
  }, [search, isFocus]);

  return (
    <Flex
      as="nav"
      align="center"
      justify="space-between"
      wrap="wrap"
      w="100%"
      mb={[4, 8]}
      pt={[2, 8]}
      pb={[2, 8]}
      pr={[4, 8]}
      pl={[2, 8]}
      bg="#fafafa"
      color="white"
      sx={{ position: '-webkit-sticky', /* Safari */ position: 'sticky', top: '0', zIndex:1 }}
      {...props}
    >
      <Logo isFocus={isFocus} align="center" mr={0} />

      <Stack
        direction={{ base: "column", md: "row" }}
        display={{ base: "block", md: "flex" }}
        width={{ base: "50%", md: "auto" }}
        alignItems="center"
        flexGrow={1}
        spacing={0}
      >
        <InputGroup>
          <InputLeftElement
            display={{ base: "flex", lg: "none" }}
            pointerEvents="none"
            height={["24px", "48px"]}
            children={<Icon as={FiSearch} color="gray.300" />}
          />
          <Input
            value={search}
            onChange={(e) => {
              setIsFocus(true);
              setSearch(e.target.value);
            }}
            onKeyDown={(e) => handleKeyDown(e)}
            placeholder="Cari barang disini..."
            bg="white"
            color="black"
            fontSize={["xs", "md"]}
            height={["24px", "40px"]}
            borderLeftRadius={{ base: "8", lg: "16" }}
            borderRightRadius={{ base: "8", lg: "0" }}
          />
          <InputRightElement
            display={{ base: isFocus ? "flex" : "none", lg: "none" }}
            pointerEvents="none"
            height={["24px", "48px"]}
            children={<Icon as={FiArrowRight} color="gray.300" />}
          />
        </InputGroup>
        <IconButton
          display={{ base: "none", lg: "flex" }}
          aria-label="Search database"
          bg="#22AFBF"
          icon={<SearchIcon />}
          borderLeftRadius="0"
          borderRightRadius="16"
          _hover={{ bg: "teal.700" }}
          onClick={(e) => doSearch(e)}
        />
      </Stack>

      <Link to={"/cart"}>
        <Icon
          as={FiShoppingCart}
          display={{ base: isFocus ? "none" : "flex", md: "flex" }}
          w={{ base: 4, md: 8 }}
          h={{ base: 4, md: 8 }}
          color="black"
          ml={8}
          mr={2}
          _hover={{ color: "#22AFBF" }}
        />
      </Link>

      <Box
        display={{ base: isOpen ? "block" : "none", md: "flex" }}
        mr={4}
        h={8}
      >
        <Divider orientation="vertical" />
      </Box>

      <Box
        display={{ base: isFocus ? "none" : "flex", md: "flex" }}
        mt={{ base: 4, md: 0 }}
      >
        {isLogin != true ? (
          <NavBarWithoutProfile action={onSignIn} />
        ) : (
          <NavBarWithProfile
            name={userData.name}
            photo={userData.photo}
            action={onLogout}
          />
        )}
      </Box>

      {/* <MenuToggle toggle={handleToggle} isOpen={isOpen} /> */}
    </Flex>
  );
};

const MenuToggle = ({ toggle, isOpen }) => {
  return (
    <Box display={{ base: "block", md: "none" }} onClick={toggle}>
      {isOpen ? (
        <CloseIcon color="#22AFBF" />
      ) : (
        <HamburgerIcon color="#22AFBF" />
      )}
    </Box>
  );
};

const Logo = ({ isOpen, isFocus, ...props }) => {
  return (
    <Box {...props}>
      <Link to={"/"}>
        <Image
          display={{ base: "none", md: "block" }}
          src={logo}
          as="img"
          w="100px"
          mr={8}
        />
        <Icon
          as={FiHome}
          display={{ base: isFocus ? "none" : "block", md: "none" }}
          w={{ base: 4, md: 8 }}
          h={{ base: 4, md: 8 }}
          color="black"
          ml={4}
          mr={4}
          _hover={{ color: "#22AFBF" }}
        />
      </Link>
    </Box>
  );
};

const CircleIcon = (props) => (
  <Icon viewBox="0 0 200 200" {...props}>
    <path
      fill="currentColor"
      d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0"
    />
  </Icon>
);

const NavBarWithoutProfile = (props) => {
  return (
    <div>
      <Button
        display={{ base: "none", lg: "center" }}
        variant="solid"
        bg="#22AFBF"
        _hover={{ bg: "teal.700" }}
        onClick={props.action}
      >
        Masuk
      </Button>
    </div>
  );
};

const NavBarWithProfile = (props) => {
  return (
    <Flex align="center">
      <Avatar
        src={props.photo}
        boxSize={{ base: "5", md: "10" }}
        ml="2"
        mb={{ base: 4, lg: 0 }}
      />
      <Text
        display={{ base: "none", lg: "center" }}
        fontSize="lg"
        color="black"
        align="center"
        mr={4}
        ml={4}
      >
        {props.name}
      </Text>
      <Button
        display={{ base: "none", lg: "center" }}
        variant="solid"
        bg="#22AFBF"
        _hover={{ bg: "teal.700" }}
        onClick={props.action}
      >
        Logout
      </Button>
    </Flex>
  );
};

export default Navbar;
