import { Box, Text, Button, Image, Heading} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Lottie from "react-lottie";
import {Link} from "react-router-dom";
import animationData from "./lottie/not_found_animation.json";

const NotFound = (props) => {
  let lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <Box w="100%" mt={{ base: "25%", lg: "10%" }} px={8} align="center" {...props}>
      <Logo align="center" mr={0} />
      <Lottie options={lottieOptions} height={200} width={300} />
      <Heading size="sm">Ups, halaman yang anda cari tidak ada</Heading>
      <Text size="sm" mt={1}>Silahkan Kembali ke menu utama</Text>
      <Button as={Link} to="/" color="teal" size="md" mt={4}>Kembali</Button>
    </Box>
  );
};

const Logo = (props) => {
    return (
      <Box {...props}>
        <Link to={"/"}>
          <Image
            src={process.env.PUBLIC_URL+"/images/logo.png"}
            w={100}
            h={50}
            mb={4}
          />
        </Link>
      </Box>
    );
  };

export default NotFound;
